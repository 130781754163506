import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    type: 'listProduct',
    product: null,
    order: null,
    orderItem: null,
    gift: null,
    policy: null,
    custom: null,
    coupon: null,
    phone: null,
    comment: null,
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setShow: (state, action) => {
            state.type = action.payload
        },
        setProductEdit: (state, action) => {
            state.type = action.payload.type
            state.product = action.payload.product
        },
        setOrderEdit: (state, action) => {
            // state.type = action.payload.type
            state.order = action.payload.order
        },
        setCommentEdit: (state, action) => {
            state.comment = action.payload
        },
        removeOrderEdit: (state, action) => {
            state.order = null
        },
        setOrderEditProduct: (state, action) => {
            state.orderItem = action.payload
        },
        updateOrderEditProduct: (state, action) => {
            const productUpdate = action.payload;
            const item = findItem(state.orderItem, productUpdate)

            if (item.length > 0) {
                state.orderItem = deleteItem(state.orderItem, productUpdate)

                state.orderItem = [...state.orderItem, {
                    ...productUpdate,
                    id: item[0].id,
                }]

                state.orderItem = sortItem(state.orderItem)
            }
        },
        deleteOrderEditProduct: (state, action) => {
            const itemRemove = action.payload

            state.orderItem = deleteItem(state.orderItem, itemRemove)
            state.orderItem = sortItem(state.orderItem)

        },
        setGift: (state, action) => {
            state.type = action.payload.type
            state.gift = action.payload.gift
        },
        setPolicy: (state, action) => {
            state.type = action.payload.type
            state.policy = action.payload.policy
        },
        setCustomEdit: (state, action) => {
            // state.type = action.payload.type
            state.custom = action.payload.custom
        },
        removeCustomEdit: (state, action) => {
            // state.type = 'listCustom'
            state.custom = null
        },
        setCoupon: (state, action) => {
            state.type = action.payload.type
            state.coupon = action.payload.coupon
        },
        setPhone: (state, action) => {
            state.type = action.payload.type
            state.phone = action.payload.phone
        }
    }
})

const findItem = (arr, item) => arr.filter(e => e.slug === item.slug && e.color === item.color && e.size === item.size)

const deleteItem = (arr, item) => arr.filter(e => e.slug !== item.slug || e.color !== item.color || e.size !== item.size)

const sortItem = (arr) => arr.sort((a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0))

export const adminActions = adminSlice.actions

export default adminSlice.reducer;
