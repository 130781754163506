import React from 'react'
import PropTypes from 'prop-types'

const ErrorMessage = props => {
    return (
        <div>
            {props.error === 'required' && <span>Bạn chưa nhập {props.label}</span>}
            {props.error === 'minLength' && <span>SĐT yêu cầu nhiều hơn 6 số</span>}
            {props.error === 'maxLength' && <span>SĐT yêu cầu ít hơn 12 số</span>}
        </div>
    )
}

ErrorMessage.propTypes = {
    error: PropTypes.string,
    label: PropTypes.string,
}

export default ErrorMessage
