import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
// import productData from '../assets/fake-data/product'
import Button from '../components/Button'
import CartItem from '../components/CartItem'
import Helmet from '../components/Helmet'
import Policy from '../components/Policy'
import TotalPrice from '../components/TotalPrice'

const Cart = () => {
    const cartItems = useSelector((state) => state.cartItems.value)

    const products = useSelector(state => state.productList.product)

    const priceShip = 300000;

    const policy = useSelector(state => state.policys.policys)

    const [cartProducts, setCartProducts] = useState([]);

    const [totalProducts, setTotalProducts] = useState(0);

    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        const getProductBySlug = (slug) => products.find(e => e.slug === slug)

        const getCartItemsInfo = (cartItems) => {
            let res = []
            if (cartItems.length > 0) {
                cartItems.forEach(e => {
                    let product = getProductBySlug(e.slug)
                    res.push({
                        ...e,
                        product: product
                    })
                })
            }
            return res.sort((a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0))
        }

        if (products.length > 0) {
            setCartProducts(getCartItemsInfo(cartItems))
        }

        setTotalProducts(cartItems.reduce((total, item) => total + (Number(item.quantity)), 0))
        setTotalPrice(cartItems.reduce((total, item) => total + (Number(item.price)) * Number(item.quantity), 0))
    }, [products, cartItems]);


    const nextPolicy = policy !== null && policy.find(x => x.price > totalPrice);
    const receiverPolicy = policy !== null && policy.filter(x => x.price < totalPrice);
    const discountPrice = Number(receiverPolicy[receiverPolicy.length - 1]?.content) || 0;

    return (
        <Helmet title='Giỏ hàng'>
            <div className="cart">
                <div className="cart__list">
                    {
                        cartProducts.map((item, index) => (
                            <CartItem item={item} key={index} />
                        ))
                    }
                </div>
                <div className="cart__info">
                    <div className="cart__info__txt">
                        <p className="cart__info__txt__quantity">Bạn đang có <span> {totalProducts} </span> sản phẩm trong giỏ hàng</p>
                        <Policy price={{ totalProducts, totalPrice, nextPolicy, receiverPolicy }} />
                        <TotalPrice price={{ totalProducts, totalPrice, priceShip, nextPolicy, receiverPolicy, discountPrice }} />
                    </div>
                    <div className="cart__info__btn">
                        <Link to="/checkout">
                            <Button size='block'>Đặt hàng</Button>
                        </Link>
                        <Link to="/catalog">
                            <Button size='block'>Tiếp tục mua hàng</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </Helmet>
    )
}

export default Cart
