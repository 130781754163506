import React from 'react'
import numberWithComas from '../utils/numberWithComas'

const TotalPrice = (props) => {
    const { totalPrice, totalProducts, price, discount, coupon } = props.price

    return (
        <div>
            <div className="cart__info__txt__price">
                <span>Giá</span>
                <span>{numberWithComas(totalPrice)}</span>
            </div>
            <div className={`cart__info__txt__price cart__info__txt__price__ship ${totalProducts >= 2 ? 'del' : ''}`}>
                <span>Phí vận chuyển</span>
                <span>{numberWithComas(20000)}</span>
            </div>
            {
                coupon && <div className={`cart__info__txt__price`}>
                    <span>Giảm giá {coupon?.code}</span>
                    <span>-{numberWithComas(coupon?.price || 0)}%</span>
                </div>
            }
            {
                discount?.sdt > 0 && <div className={`cart__info__txt__price`}>
                    <span> Giảm giá Khách hàng vip</span>
                    <span>-{numberWithComas(10)}%</span>
                </div>
            }
            <div className="cart__info__txt__price">
                <span>Thành tiền</span>
                <span>{numberWithComas(price - (discount?.sdt || 0) - (discount?.coupon || 0))}</span>
            </div>
        </div>
    )
}

export default TotalPrice
