import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PreviewImage } from './PreviewImage';

const Comment = props => {
    const rating = props.comment.rating;
    const [image, setImage] = useState(undefined)
    const [show, setShow] = useState(false)

    const handleViewImage = () => {
        setImage(props.comment.image01);
        setShow(true)
    }

    return (
        <div className="comment">
            <div className="comment__content">
                <div className="comment__content__reviews">
                    <i className={`bx ${rating >= 1 ? 'bxs-star' : 'bx-star'}`} ></i>
                    <i className={`bx ${rating >= 2 ? 'bxs-star' : 'bx-star'}`} ></i>
                    <i className={`bx ${rating >= 3 ? 'bxs-star' : 'bx-star'}`} ></i>
                    <i className={`bx ${rating >= 4 ? 'bxs-star' : 'bx-star'}`} ></i>
                    <i className={`bx ${rating >= 5 ? 'bxs-star' : 'bx-star'}`} ></i>
                </div>
                <strong>{props.comment.name}</strong>
                <p>{props.comment.comment}</p>
            </div>
            <div className="comment__heading">
                <div className="comment__heading__profile" onClick={handleViewImage}>
                    <img src={props.comment.image01} alt='' />
                </div>
            </div>
            <PreviewImage image={image} show={show} setShow={setShow} />
        </div>
    )
}

Comment.propTypes = {
    product: PropTypes.object,
    comment: PropTypes.object,
}

export default Comment
