import axios from 'axios'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API_URL } from '../constants/constants'
import { LoadingActions } from '../redux/loading/loadingSlice'
import { OrderListActions } from '../redux/order-list/orderListSlice'
import OrderItem from './OrderItem'

const OrderList = () => {
    const dispatch = useDispatch()

    const order = useSelector(state => state.orderList.order)

    useEffect(() => {
        const getListOrder = async () => {
            dispatch(OrderListActions.getOrder());
            dispatch(LoadingActions.setLoading());
            const res = await axios.get(`${API_URL}/order`)
            if (res.data.success) {
                dispatch(OrderListActions.getOrderSuccess(res.data.order))
                dispatch(LoadingActions.loadingSuccess());
            }
        }
        getListOrder()
    }, [dispatch])

    return (
        <div className="admin__content__list__order">
            <h1>Danh sách đơn hàng</h1>
            <table>
                <thead>
                    <tr>
                        <th>IP</th>
                        <th>Tên khách hàng</th>
                        <th>SĐT</th>
                        <th>Địa chỉ</th>
                        <th>Tổng tiền</th>
                        <th>Thời gian</th>
                        <th>Trạng thái</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        order !== null && order.map((item, index) => (
                            <OrderItem
                                key={index}
                                name={item.name}
                                add={item.add}
                                sdt={item.sdt}
                                price={Number(item.price)}
                                order={item}
                            >
                            </OrderItem>
                        ))
                    }
                </tbody>
            </table>

        </div>
    )
}

export default OrderList
