import React from 'react'
import { Link } from 'react-router-dom'
import Helmet from '../components/Helmet'

const Error = () => {

    return (
        <Helmet title='Thất bại' className="thankyou">
            <div className="thankyou__content">
                <div className="thankyou__content__title">
                    <h1>Mum big Street xin trân thành cảm ơn quý khách.</h1>
                    <h2>Rất tiếc quý khách chưa thanh toán thành công!.</h2>
                </div>
                <div className="thankyou__content__btn">
                    <Link to="/">Quay lại trang chủ</Link>
                    <Link to="/catalog">Tiếp tục mua hàng</Link>
                </div>
            </div>
        </Helmet>
    )
}

export default Error
