import React from 'react'
import PropTypes from 'prop-types'
import numberWithComas from '../utils/numberWithComas'
import { useDispatch } from 'react-redux'
import { adminActions } from '../redux/admin/adminSlice'
import { OrderListActions } from '../redux/order-list/orderListSlice'
import axios from 'axios'
import { API_URL } from '../constants/constants'
import StatusOrder from './StatusOrder'
import { LoadingActions } from '../redux/loading/loadingSlice'

const OrderItem = props => {
    const dispatch = useDispatch();

    const handleEditOrder = () => {
        dispatch(adminActions.setOrderEdit({
            order: props.order
        }))

        dispatch(adminActions.setOrderEditProduct(props.order.product))
    }

    const handleDeleteOrder = () => {
        if (window.confirm('Bạn có chắc chắn muốn xóa đơn hàng này!')) {
            const deleteOrder = async () => {
                dispatch(LoadingActions.setLoading())
                const res = await axios.delete(`${API_URL}/order/${props.order._id}`)
                if (res.data.success) {
                    dispatch(LoadingActions.loadingSuccess())
                    dispatch(OrderListActions.deleteOrder(props.order._id))
                    alert("Xóa đơn hàng thành công!")
                }
            }
            deleteOrder()
        }
    }

    return (
        <tr>
            <td style={{ width: "10%", fontSize: "12px" }}>{props.order.ip.slice(7)}</td>
            <td>{props.name}</td>
            <td>{props.sdt}</td>
            <td className="admin__content__list__order__add">{props.add}</td>
            <td>{numberWithComas(props.price)}</td>
            <td>{props.order.createAt.slice(0, 10)}</td>
            <td>
                <StatusOrder order={props.order} />
            </td>
            <td><i onClick={handleEditOrder} className="bx bx-edit"></i></td>
            <td><i onClick={handleDeleteOrder} className="bx bx-trash"></i></td>
        </tr>
    )
}

OrderItem.propTypes = {
    name: PropTypes.string,
    add: PropTypes.string,
    sdt: PropTypes.string,
    price: PropTypes.number,
    order: PropTypes.object,
}

export default OrderItem
