import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '../components/Grid'
import Helmet from '../components/Helmet'
import ProductCard from '../components/ProductCard'
import Section, { SectionBody, SectionTitle } from '../components/Section'

const Sales = () => {
    const products = useSelector(state => state.productList.product)

    // const getProductByTag = (tag) => {
    //     const productByTag = products.filter(pd => pd.tag.includes(tag));
    //     return productByTag
    // }

    const getProductByCategory = (cate) => {
        const productByCategory = products.filter(pd => pd.categorySlug === cate);
        return productByCategory
    }

    return (
        <Helmet title="Siêu Khuyến Mãi">
            <Section className="sales">
                <SectionTitle>
                    <p>Siêu khuyến mãi</p>
                </SectionTitle>
                <SectionBody>
                    <Grid
                        col={4}
                        mdCol={2}
                        smCol={2}
                        gap={20}
                    >
                        {
                            getProductByCategory('ao-chong-nang-big-size').map((item, index) => (
                                <ProductCard
                                    key={index}
                                    img01={item.image01}
                                    img02={item.image02}
                                    name={item.title}
                                    price={Number(item.price)}
                                    oldPrice={Number(item.oldPrice)}
                                    slug={item.slug}
                                    id={item._id}
                                    tag={item.tag}
                                    rating={item.rating ? item.rating : 5}
                                    sold={item.sold ? item.sold : 0}
                                >
                                </ProductCard>
                            ))
                        }
                    </Grid>
                </SectionBody>
            </Section>
        </Helmet>
    )
}

export default Sales
