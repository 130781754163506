import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from './Button'
import numberWithComas from '../utils/numberWithComas'

import { useDispatch, useSelector } from 'react-redux'

import { set } from '../redux/product-modal/productModalSlice'
import SaleTag from './SaleTag'


const ProductCard = props => {
    const images = useSelector(state => state.imagesList.images)

    const imagesOfProduct = (productId) => {
        return images.find(x => x.productId === productId)?.images
    }

    const albums = imagesOfProduct(props.id)?.slice(0, 3);

    // const [image, setImage] = useState('img01')
    const [image, setImage] = useState(undefined)

    const dispatch = useDispatch();

    return (
        <div className="product-card">
            {props.tag.length > 0 && <SaleTag type={props.tag} />}
            <Link to={`/catalog/${props.slug}`}>
                <div className="product-card__image">
                    {/* <img src={`.././uploads/${props.img01}`} alt="" className={`${image === 'img01' ? 'active' : ''}`} />
                    <img src={`.././uploads/${props.img02}`} alt="" className={`${image === 'img02' ? 'active' : ''}`} /> */}
                    {/* <img src={`.././uploads/${image ? image : props.img01}`} alt="" /> */}
                    <img src={image ? image : props.img01} alt="" />
                </div>
            </Link >
            <div className="product-card__switch">
                <div onMouseOver={() => setImage(props.img01)} className="product-card__switch__image">
                    {/* <img src={`.././uploads/${props.img01}`} alt="" /> */}
                    <img src={props.img01} alt="" />
                </div>
                <div onMouseOver={() => setImage(props.img02)} className="product-card__switch__image">
                    {/* <img src={`.././uploads/${props.img02}`} alt="" /> */}
                    <img src={props.img02} alt="" />
                </div>
                {
                    albums && albums.map((img, index) => (
                        <div key={index} onMouseOver={() => setImage(img)} className="product-card__switch__image">
                            {/* <img src={`.././uploads/${img}`} alt="" /> */}
                            <img src={img} alt="" />
                        </div>
                    ))
                }
            </div>
            <div className="product-card__rating">
                {
                    <div className="product-card__rating__item">
                        <i className={`bx ${props.rating > 0 ? (props.rating > 0.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                        <i className={`bx ${props.rating > 1 ? (props.rating > 1.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                        <i className={`bx ${props.rating > 2 ? (props.rating > 2.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                        <i className={`bx ${props.rating > 3 ? (props.rating > 3.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                        <i className={`bx ${props.rating >= 4 ? (props.rating > 4.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `}></i>
                    </div>
                }
                {
                    <strong>({props.sold ? props.sold : 0} đã bán)</strong>
                }
            </div>
            <Link to={`/catalog/${props.slug}`}>
                <h3 className="product-card__name">{props.name}</h3>
                <div className="product-card__price">
                    {numberWithComas(props.price)}
                    {
                        props.oldPrice > 0 ? <span className="product-card__price__old">
                            <del>{numberWithComas(props.oldPrice)}</del>
                        </span> : ''
                    }
                </div>
            </Link >

            <div className="product-card__btn">
                <Button
                    size="sm"
                    icon="bx bx-cart"
                    animate={true}
                    onClick={() => dispatch(set(props.slug))}
                >
                    Chọn mua
                </Button>
            </div>
        </div >
    )
}

ProductCard.propTypes = {
    img01: PropTypes.string.isRequired,
    img02: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    oldPrice: PropTypes.number,
}

export default ProductCard
