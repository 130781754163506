import axios from 'axios';
import React from 'react'
import { useDispatch } from 'react-redux';
import { API_URL } from '../constants/constants';
import { adminActions } from '../redux/admin/adminSlice';
import { CouponActions } from '../redux/coupon-list/couponListSlice';
import { LoadingActions } from '../redux/loading/loadingSlice';
import numberWithComas from '../utils/numberWithComas';

const CouponListItem = (props) => {
    const dispatch = useDispatch()

    const handleEditCoupon = () => {
        dispatch(adminActions.setCoupon({
            type: 'editCoupon',
            coupon: props.coupon
        }))
    }

    const handleDeleteCoupon = () => {
        if (window.confirm('Bạn có chắc chắn muốn xóa!')) {
            const deleteCoupon = async () => {
                dispatch(LoadingActions.setLoading())
                const res = await axios.delete(`${API_URL}/coupon/${props.coupon._id}`)
                if (res.data.success) {
                    dispatch(CouponActions.deleteCoupon(props.coupon._id))
                    dispatch(LoadingActions.loadingSuccess())
                    alert('Xóa thành công!')
                }
            }
            deleteCoupon()
        }
    }

    return (
        <tr>
            <td>{props.coupon.code}</td>
            <td>{props.coupon.quantity}</td>
            <td>{props.coupon.type === 'giam-tien' ? 'Giảm tiền : ' + numberWithComas(props.coupon.price) : 'Giảm : ' + numberWithComas(props.coupon.price) + '%'}</td>
            <td><i onClick={handleEditCoupon} className="bx bx-edit"></i></td>
            <td><i onClick={handleDeleteCoupon} className="bx bx-trash"></i></td>
        </tr>
    )
}

export default CouponListItem
