import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../constants/constants';
import { productListActions } from '../redux/product-list/productListSlice';

//text edittor 
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { LoadingActions } from '../redux/loading/loadingSlice';
import category from '../assets/fake-data/catagory';

const EditProduct = () => {

    const dispatch = useDispatch()

    const product = useSelector(state => state.admin.product)
    const { register, handleSubmit, formState: { errors } } = useForm();

    //text edittor
    const blocksFromHtml = htmlToDraft(product.description);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState1 = EditorState.createWithContent(contentState);

    const [editorState, SetEditorState] = useState(editorState1)

    const onEditorStateChange = (editorState) => {
        SetEditorState(editorState)
    };


    const onSubmit = data => {
        const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        const tag = data.tag.split(",");

        if (data.price > 0 && data.oldPrice > 0) {
            if (tag.findIndex(x => x.trim().toLowerCase() === 'sale') < 0) {
                tag.push('sale')
            }
        }

        if (data.sold >= 200) {
            if (tag.findIndex(x => x.trim().toLowerCase() === 'hot') < 0) {
                tag.push('hot')
            }
        }

        const updateProduct = {
            ...data,
            description,
            image01: data.image01 || product.image01,
            image02: data.image02 || product.image02,
            colors: data.colors.split(","),
            tag,
            size: data.size.split(","),
        }
        const npd = JSON.stringify(updateProduct)

        const formData = new FormData();

        formData.set('product', npd)

        dispatch(LoadingActions.setLoading());
        axios.put(`${API_URL}/product/${product._id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                if (data.images.length > 0) {
                    // formData.set('productId', product._id)

                    // const albums = data.images.split(",");
                    // for (let index = 0; index < data.images.length; index++) {
                    //     formData.append('images', data.images[index])
                    // }

                    // dispatch(productListActions.editProduct(res.data.product))

                    // axios.post(`${API_URL}/images`, formData, {
                    //     headers: {
                    //         'Content-Type': 'multipart/form-data'
                    //     }
                    // })
                    //     .then((res) => {
                    //         alert('Thêm ảnh thành công!');
                    //         dispatch(LoadingActions.loadingSuccess());
                    //     })
                    //     .catch((err) => {
                    //         console.log('err', err)
                    //     })

                    const albums = {
                        productId: product._id,
                        images: data.images
                    }
                    dispatch(productListActions.editProduct(res.data.product))
                    axios.post(`${API_URL}/images`, albums)
                        .then((res) => {
                            alert('Thêm ảnh thành công!');
                            dispatch(LoadingActions.loadingSuccess());
                        })
                        .catch((err) => {
                            console.log('err', err)
                        })

                } else {
                    dispatch(productListActions.editProduct(res.data.product))
                    dispatch(LoadingActions.loadingSuccess());
                    alert('Sửa sản phẩm thành công!');
                }
            })
            .catch((err) => {
                console.log('err', err)
            })

    }

    return (
        <div className="admin__content__add__product">
            <h1>Sửa sản phẩm</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    {...register("title", { required: true })}
                    defaultValue={product.title}
                    placeholder="Tên sản phẩm"
                />
                {errors.title && <span>Tên sản phẩm không được bỏ trống</span>}

                <input
                    {...register("price", { required: true })}
                    defaultValue={product.price}
                    placeholder="Giá sản phẩm"
                />
                {errors.price && <span>Giá sản phẩm không được bỏ trống</span>}

                <input
                    {...register("oldPrice")}
                    defaultValue={product.oldPrice}
                    placeholder="Giá cũ"
                />

                {/* <input
                    {...register("categorySlug", { required: true })}
                    defaultValue={product.categorySlug}
                    placeholder="Loại sản phẩm"
                /> */}
                {errors.categorySlug && <span>Loại sản phẩm không được bỏ trống</span>}

                <select {...register("categorySlug", { required: true })} defaultValue={product.categorySlug}>
                    {category && category.map((item, index) =>
                        <option key={index} value={item.categorySlug}>{item.display}</option>
                    )}
                </select>
                {errors.categorySlug && <span className='admin__content__add__product__error'>Loại sản phẩm không được bỏ trống</span>}

                <input
                    {...register("slug", { required: true })}
                    defaultValue={product.slug}
                    placeholder="Nhãn của sản phẩm"
                />
                {errors.slug && <span>Nhãn sản phẩm không được bỏ trống</span>}

                <input
                    {...register("colors", { required: true })}
                    defaultValue={product.colors.toString()}
                    placeholder="Màu"
                />
                {errors.colors && <span>Màu sản phẩm không được bỏ trống</span>}

                <input
                    {...register("size", { required: true })}
                    defaultValue={product.size.toString()}
                    placeholder="Size"
                />
                {errors.size && <span>Kích thước sản phẩm không được bỏ trống</span>}

                <input
                    {...register("tag")}
                    placeholder="Tag sản phẩm (ví dụ : hot,new,....)"
                    defaultValue={product.tag.toString()}
                />

                <input
                    {...register("sold")}
                    placeholder="Số lượng đã bán"
                    defaultValue={product.sold}
                />

                <input
                    {...register("timeShip")}
                    placeholder="Thời gian giao hàng"
                    defaultValue={product.timeShip}
                />

                {/* <input
                    {...register("image01")}
                    type="file"
                /> */}
                <input
                    {...register("image01")}
                    type="text"
                    placeholder='Ảnh đại diện 1'
                />
                <img className='edit-image' src={product.image01} alt="" />

                {/* <input
                    {...register("image02")}
                    type="file"
                /> */}
                <input
                    {...register("image02")}
                    type="text"
                    placeholder='Ảnh đại diện 2'
                />
                <img className='edit-image' src={product.image02} alt="" />

                {/* <textarea
                    {...register("description")}
                    defaultValue={product.description}
                    placeholder="Mô tả chi tiết về sản phẩm">
                </textarea> */}

                <Editor
                    defaultContentState={contentState}
                    // onContentStateChange={setContentState}
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={onEditorStateChange}
                />

                {/* <input
                    {...register("images")}
                    type="file"
                    multiple
                /> */}
                <input
                    {...register("images")}
                    type="text"
                    placeholder='Albums Ảnh'
                />


                <button type="submit" className='btn bg-main btn-block' >Sửa sản phẩm</button>
            </form>
        </div>
    )
}

export default EditProduct
