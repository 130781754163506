import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    policys: []
}

export const policySlice = createSlice({
    name: 'policys',
    initialState,
    reducers: {
        getPolicy: (state, action) => {
            state.loading = true
        },
        getPolicySuccess: (state, action) => {
            const poli = action.payload;

            const policy = poli.sort((a, b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0))

            return {
                loading: false,
                policys: policy
            }
        },
        addPolicy: (state, action) => {
            state.policys = [...state.policys, action.payload]
        },
        editPolicy: (state, action) => {
            const updatePolicy = action.payload;
            const policyIndex = state.policys.findIndex(x => x._id === updatePolicy._id)
            if (policyIndex >= 0) {
                state.policys[policyIndex] = updatePolicy
            }
        },
        deletePolicy: (state, action) => {
            const policyId = action.payload;
            state.policys = state.policys.filter(x => x._id !== policyId)
        }
    }
})

export const PolicyActions = policySlice.actions

export default policySlice.reducer
