import axios from 'axios'
import React, { useEffect } from 'react'
import { ACCESS_TOKEN_NAME, API_URL } from '../constants/constants'
import setAuthToken from '../utils/setAuthToken'
import { useDispatch, useSelector } from 'react-redux'
import { loginActions } from '../redux/login/loginSlice'
import { Redirect } from 'react-router-dom'
import Helmet from '../components/Helmet'
import ProductListItem from '../components/ProductListItem'
import AddProduct from '../components/AddProduct'
import EditProduct from '../components/EditProduct'
import { adminActions } from '../redux/admin/adminSlice'
import OrderList from '../components/OrderList'
import EditOrder from '../components/EditOrder'
import AddGift from '../components/AddGift'
import GiftListItem from '../components/GiftListItem'
import EditGift from '../components/EditGift'
import { GiftActions } from '../redux/gift-list/giftListSlice'
import { LoadingActions } from '../redux/loading/loadingSlice'
import AddPolicy from '../components/AddPolicy'
import PolicyListItem from '../components/PolicyListItem'
import EditPolicy from '../components/EditPolicy'
import CouponListItem from '../components/CouponListItem'
import AddCoupon from '../components/AddCoupon'
import { CouponActions } from '../redux/coupon-list/couponListSlice'
import EditCoupon from '../components/EditCoupon'
import AddPhone from '../components/AddPhone'
import EditPhone from '../components/EditPhone'
import PhoneListItem from '../components/PhoneListItem'
import { PhoneActions } from '../redux/phone-list/phoneListSlice'
import ListComment from '../components/ListComment'
import EditComment from '../components/EditComment'
import ImportPhone from '../components/ImportPhone'

const Admin = () => {
    const products = useSelector(state => state.productList.product)

    const order = useSelector(state => state.admin.order)

    const show = useSelector(state => state.admin.type)

    const dispatch = useDispatch();

    const userLogged = useSelector(state => state.login.isAuthenticated);

    const gift = useSelector(state => state.gifts.gifts)
    const policys = useSelector(state => state.policys.policys)
    const coupons = useSelector(state => state.couponList.coupons)
    const phones = useSelector(state => state.phoneList.phones)

    //authentication 
    useEffect(() => {
        const loadUser = async () => {
            if (localStorage[ACCESS_TOKEN_NAME]) {
                setAuthToken(localStorage[ACCESS_TOKEN_NAME])
            }

            try {
                const response = await axios.get(`${API_URL}/auth`)
                if (response.data.success) {
                    dispatch(loginActions.setAuth({ isAuthenticated: true, user: response.data.user }))
                }
            } catch (error) {
                localStorage.removeItem(ACCESS_TOKEN_NAME)
                localStorage.removeItem('isAuthenticated')
                setAuthToken(null)
                dispatch(loginActions.loginFailed())
            }
        }
        loadUser()
    }, [dispatch]);

    //gift list
    useEffect(() => {
        const loadGift = async () => {
            try {
                dispatch(GiftActions.getGift())
                dispatch(LoadingActions.setLoading())
                const res = await axios.get(`${API_URL}/gift`)
                if (res.data.success) {
                    dispatch(GiftActions.getGiftSuccess(res.data.gift))
                    dispatch(LoadingActions.loadingSuccess())
                }
            } catch (error) {
                console.log(error);
            }
        }
        loadGift();
    }, [dispatch])

    //coupon list 
    useEffect(() => {
        const loadCoupon = async () => {
            try {
                dispatch(CouponActions.getCoupon())
                dispatch(LoadingActions.setLoading())
                const res = await axios.get(`${API_URL}/coupon`)
                if (res.data.success) {
                    dispatch(CouponActions.getCouponSuccess(res.data.coupon))
                    dispatch(LoadingActions.loadingSuccess())
                }
            } catch (error) {
                console.log(error);
            }
        }
        loadCoupon();
    }, [dispatch])

    //phone list 
    useEffect(() => {
        const loadPhone = async () => {
            try {
                dispatch(PhoneActions.getPhone())
                dispatch(LoadingActions.setLoading())
                const res = await axios.get(`${API_URL}/phone`)
                if (res.data.success) {
                    dispatch(PhoneActions.getPhoneSuccess(res.data.phones))
                    dispatch(LoadingActions.loadingSuccess())
                }
            } catch (error) {
                console.log(error);
            }
        }
        loadPhone();
    }, [dispatch])

    const handleImportPhone = () => {
        console.log('hihi')
    }

    return (
        userLogged ?
            <Helmet title='Admin'>
                <div className="admin">
                    <div className="admin__menu__left">
                        <div className="admin__menu__left__close">
                            <i className="bx bx-left-arrow-alt"></i>
                        </div>
                        <h1>Danh mục</h1>
                        <ul>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('addProduct'))
                                    window.scrollTo(0, 0);
                                }}
                            >
                                Thêm sản phẩm mới
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('listProduct'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Danh sách sản phẩm
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('listOrder'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Danh sách đơn hàng
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('addGift'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Thêm khuyến mãi mới
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('listGift'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Danh sách khuyến mãi
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('addPolicy'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Thêm chính sách
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('listPolicy'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Danh sách chính sách
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('listCustom'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Danh sách Custom
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('addCoupon'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Thêm mã giảm giá
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('listCoupon'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Danh sách mã giảm giá
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('addPhone'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Thêm SĐT giảm giá
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('listPhone'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Danh sách SĐT giảm giá
                            </li>
                            <li
                                onClick={() => {
                                    dispatch(adminActions.setShow('listComment'))
                                    window.scrollTo(0, 0);
                                }
                                }
                            >
                                Danh sách Comment
                            </li>
                        </ul>
                    </div>
                    <div className="admin__content">
                        {/* Product */}
                        {show === 'addProduct' && <AddProduct />}
                        {
                            show === 'listProduct' && <div className="admin__content__list__product">
                                <h1>Danh sách sản phẩm</h1>
                                {
                                    products !== null && products.map((item, index) => (
                                        <ProductListItem
                                            key={index}
                                            img01={item.image01}
                                            img02={item.image02}
                                            name={item.title}
                                            price={Number(item.price)}
                                            slug={item.slug}
                                            product={item}
                                        >
                                        </ProductListItem>
                                    ))
                                }
                            </div>
                        }
                        {show === 'editProduct' && <EditProduct />}

                        {/* Order */}
                        {show === 'listOrder' && <OrderList />}
                        {order && <EditOrder />}

                        {/* Gift */}
                        {show === 'addGift' && <AddGift />}
                        {
                            show === 'listGift' && <div className="admin__content__list__gift">
                                <h1>Danh sách khuyến mãi</h1>
                                {
                                    gift.length > 0 && gift.map((item, index) => (
                                        <GiftListItem
                                            key={index}
                                            gift={item}
                                        >
                                        </GiftListItem>
                                    ))
                                }
                            </div>
                        }
                        {show === 'editGift' && <EditGift />}

                        {/* Policy */}
                        {show === 'addPolicy' && <AddPolicy />}
                        {
                            show === 'listPolicy' && <div className="admin__content__list__order">
                                <h1>Danh sách chính sách bán hàng</h1>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Loại hình</th>
                                            <th>Giá từ</th>
                                            <th>Số tiền giảm</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            policys.length > 0 && policys.map((item, index) => (
                                                <PolicyListItem key={index} policy={item} />
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        {show === 'editPolicy' && <EditPolicy />}

                        {/* Coupon */}
                        {show === 'addCoupon' && <AddCoupon />}
                        {
                            show === 'listCoupon' && <div className="admin__content__list__order">
                                <h1>Danh sách mã giảm giá</h1>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Mã</th>
                                            <th>Số lượng</th>
                                            <th>Loại hình</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            coupons.length > 0 && coupons.map((item, index) => (
                                                <CouponListItem key={index} coupon={item} />
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        {show === 'editCoupon' && <EditCoupon />}

                        {show === 'addPhone' && <AddPhone />}
                        {
                            show === 'listPhone' && <div className="admin__content__list__order">
                                <h1>Danh sách sđt giảm giá</h1>
                                <ImportPhone />
                                <table>
                                    <thead>
                                        <tr>
                                            <th>SĐT</th>
                                            <th>Tên khách hàng</th>
                                            <th>Giảm giá</th>
                                            <th>Cấp độ</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            phones.length > 0 && phones.map((item, index) => (
                                                <PhoneListItem key={index} phone={item} />
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        {show === 'editPhone' && <EditPhone />}
                        {show === 'listComment' && <ListComment />}
                        {show === 'editComment' && <EditComment />}
                    </div>
                </div>
            </Helmet>
            : <Redirect to='/admin/login' />
    )
}

export default Admin
