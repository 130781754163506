import React from 'react'

const Gift = (props) => {

    return (
        <div className="gift">
            <div className="gift__title">
                <h2>Quà tặng: </h2>
            </div>
            <div className="gift__content" dangerouslySetInnerHTML={{ __html: props.gift.content }} >
            </div>
        </div>
    )
}

export default Gift
