import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    gifts: []
}

export const giftSlice = createSlice({
    name: 'gifts',
    initialState,
    reducers: {
        getGift: (state, action) => {
            state.loading = true
        },
        getGiftSuccess: (state, action) => {
            return {
                loading: false,
                gifts: action.payload
            }
        },
        addGift: (state, action) => {
            state.gifts = [...state.gifts, action.payload]
        },
        editGift: (state, action) => {
            const updateGift = action.payload.updatedGift;
            const productSlug = action.payload.productSlug;
            const gifIndex = state.gifts.findIndex(x => x.productSlug === productSlug)
            if (gifIndex >= 0) {
                state.gifts[gifIndex] = updateGift
            }
        },
        deleteGift: (state, action) => {
            const giftId = action.payload;
            state.gifts = state.gifts.filter(x => x._id !== giftId)
        }
    }
})

export const GiftActions = giftSlice.actions

export default giftSlice.reducer
