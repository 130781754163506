import React from 'react'
import Helmet from '../components/Helmet'

const PolicyRefund = () => {
    return (
        <Helmet title='Chính sách đổi hàng'>
            <div className="policy-page">
                <h1>Chính sách</h1>
                <div className="policy-page__check">
                    <h2>2. CHÍNH SÁCH ĐỔI HÀNG</h2>
                    <div className="policy-page__check__content">
                        <h3>ĐIỀU KIỆN ĐỔI HÀNG</h3>
                        <p>Mũm nhận đổi/trả sản phẩm với các quy định sau:</p>
                        <p>- Thời gian đổi hàng trong vòng 15 ngày kể từ ngày nhận hàng</p>
                        <p>- Thời gian được tính từ thời điểm xuất hóa đơn</p>
                        <p>- Sản phẩm chưa qua sử dụng, không bị dơ bẩn, còn nguyên tem mác, bao bì sản phẩm (nếu có)</p>
                        <p>- Sản phẩm được chọn để đổi phải có giá trị cao hơn hoặc ngang bằng sản phẩm đổi</p>
                        <p>- Không hoàn lại tiền thừa trong trường hợp sản phẩm được chọn để đổi có giá trị thấp hơn sản phẩm đổi</p>
                    </div>
                    <div className="policy-page__check__ps">
                        <h3>CÁC TRƯỜNG HỢP ĐỔI HÀNG</h3>
                        <span>Về phía Mũm - Miễn phí đổi hàng cho khách mua sắm tại Mũm trong các trường hợp:</span>
                        <p>- Sản phẩm bị lỗi từ nhà sản xuất </p>
                        <p>- Giao nhầm hàng, nhầm sản phẩm </p>
                        <p>- Hư hỏng trong quá trình vận chuyển </p>
                        <span>Về phía khách hàng:</span>
                        <p>- Trong trường hợp không thích màu, muốn đổi mẫu khác/sản phẩm khác.</p>
                        <p>- Khách hàng vui lòng hỗ trợ 2 chiều phí vận chuyển đổi hàng tại kho Mũm (phí vận chuyển sẽ là 30.000VNĐ)</p>
                    </div>
                </div>
            </div>
        </Helmet>
    )
}

export default PolicyRefund