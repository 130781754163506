import React from 'react'
import numberWithComas from '../utils/numberWithComas'

const Policy = (props) => {
    const { totalPrice, nextPolicy, receiverPolicy, totalProducts } = props.price;

    return (
        <div className="policy">
            {
                <div>
                    {
                        totalProducts >= 2 &&
                        <div className="policy__received">
                            <h3>Ưu đãi nhận được: </h3>
                            <div className="policy__title">
                                <i className='bx bxs-purchase-tag'></i>
                                <p>Miễn phí ship cho đơn hàng có số lượng từ 2 trở lên.</p>
                            </div>
                            {
                                receiverPolicy.length > 0 && (
                                    receiverPolicy[receiverPolicy.length - 1].type === 'giam-gia' ?
                                        <div className="policy__title">
                                            <i className='bx bxs-purchase-tag'></i>
                                            <p>Giảm giá <strong>{numberWithComas(receiverPolicy[receiverPolicy.length - 1].content)}%</strong> cho đơn hàng từ {receiverPolicy[receiverPolicy.length - 1].price / 1000}k trở lên.</p>
                                        </div> :
                                        <div className="policy__title" >
                                            <i className='bx bxs-purchase-tag'></i>
                                            <div dangerouslySetInnerHTML={{ __html: receiverPolicy[receiverPolicy.length - 1].content }}></div>
                                        </div>
                                )
                            }
                        </div>
                    }
                    {
                        totalProducts >= 2 ? nextPolicy && <div className="policy__update">
                            <h2>Gợi ý: </h2>
                            <div className="policy__update__title">
                                <i className='bx bx-gift bx-tada'></i>
                                Chỉ cần <strong>{(Number(nextPolicy.price) - totalPrice) / 1000}k</strong> để đạt được ưu đãi tiếp theo!
                            </div>
                            {
                                nextPolicy.type === 'tang-qua' && <div className="policy__update__next" dangerouslySetInnerHTML={{ __html: nextPolicy.content }} >
                                </div>
                            }
                            {
                                nextPolicy.type === 'giam-gia' && <div className="policy__update__next" >
                                    Giảm giá <strong>{numberWithComas(nextPolicy.content)}%</strong> cho đơn hàng từ {nextPolicy.price / 1000}k trở lên.
                                </div>
                            }
                        </div> :
                            <div className="policy__update">
                                <h2>Gợi ý: </h2>
                                <div className="policy__update__title">
                                    <i className='bx bx-gift bx-tada'></i>
                                    Chỉ cần thêm <strong>{2 - totalProducts}</strong> sản phẩm để được miễn phí vận chuyển!
                                </div>
                                <div className="policy__update__next">
                                    Miễn phí ship cho đơn hàng có số lượng từ 2 trở lên.
                                </div>
                            </div>
                    }
                </div>
            }
        </div>
    )
}

export default Policy
