import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    product: []
}

export const productListSlice = createSlice({
    name: 'productList',
    initialState,
    reducers: {
        getListProduct: (state, action) => {
            state.loading = true;
        },
        getListProductSuccess: (state, action) => {
            return {
                loading: false,
                product: action.payload.reverse()
            }
        },
        addProduct: (state, action) => {
            state.product = [
                ...state.product,
                action.payload
            ]
        },
        editProduct: (state, action) => {
            const updateProduct = action.payload;
            const productIndex = state.product.findIndex(product => product._id === updateProduct._id);
            if (productIndex >= 0) {
                state.product[productIndex] = updateProduct;
            }
        },
        deleteProduct: (state, action) => {
            const deleteProductId = action.payload;
            const newProduct = state.product.filter(product => product._id !== deleteProductId)
            state.product = newProduct
        }
    }
})

export const productListActions = productListSlice.actions

export default productListSlice.reducer
