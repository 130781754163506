import React from 'react'

const Loading = (props) => {
    return (
        <div className={`loading ${props.loading ? 'active' : ''}`}>
            <div className="loading__content">
                <h1 data-text="Loading...">Loading...</h1>
            </div>
        </div>
    )
}

export default Loading
