import React, { useEffect } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

import Routes from '../routes/Routes';
import ProductViewModal from './ProductViewModal';
import { useDispatch, useSelector } from 'react-redux';
import { productListActions } from '../redux/product-list/productListSlice';
import { API_URL } from '../constants/constants';
import axios from 'axios';
import Loading from './Loading';
import { LoadingActions } from '../redux/loading/loadingSlice';
import { imagesListAction } from '../redux/images-list/imagesListSlice';
import CustomChat from './CustomChat';

const Layout = () => {
    const dispatch = useDispatch()

    const loading = useSelector(state => state.loading.loading)

    //get list product
    useEffect(() => {
        const getListProduct = async () => {
            dispatch(productListActions.getListProduct());
            dispatch(LoadingActions.setLoading());
            const res = await axios.get(`${API_URL}/product`)
            if (res.data.success) {
                dispatch(productListActions.getListProductSuccess(res.data.product))
                dispatch(LoadingActions.loadingSuccess());
            }
        }
        getListProduct()
    }, [dispatch])

    //get album product
    useEffect(() => {
        const getListImage = async () => {
            dispatch(LoadingActions.setLoading());
            dispatch(imagesListAction.getImages())
            const res = await axios.get(`${API_URL}/images`)
            if (res.data.success) {
                dispatch(imagesListAction.getImagesSuccess(res.data.images))
                dispatch(LoadingActions.loadingSuccess());
            }
        }
        getListImage()
    }, [dispatch])

    return (
        <BrowserRouter>
            <Route render={props => (
                <div>
                    <Header {...props} />
                    <div className="container">
                        <div className="main">
                            <Routes />
                        </div>
                    </div>
                    <Footer />
                    <ProductViewModal />
                    <Loading loading={loading ? true : false} />
                    <CustomChat />
                </div>
            )} />
        </BrowserRouter>
    )
}

export default Layout
