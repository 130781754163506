import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo714trang.png';
import { useDispatch, useSelector } from 'react-redux'
import numberWithComas from '../utils/numberWithComas';
import { removeItem } from '../redux/shopping-cart/cartItemsSlice';
import { PolicyActions } from '../redux/policy-list/policyListSlice';
import { LoadingActions } from '../redux/loading/loadingSlice';
import axios from 'axios';
import { API_URL } from '../constants/constants';


const mainNav = [
    {
        display: "Trang chủ",
        path: "/"
    },
    {
        display: "Sản phẩm",
        path: "/catalog"
    },
    // {
    //     display: "Khuyến mãi",
    //     path: "/sales"
    // },
    {
        display: "Áo chống nắng",
        path: "/ao-chong-nang-big-size"
    },
    {
        display: "About Us",
        path: "/about"
    }
]

const Header = () => {
    const { pathname } = useLocation();

    const dispatch = useDispatch()

    const cartItems = useSelector((state) => state.cartItems.value)
    const policy = useSelector(state => state.policys.policys)

    const [productQuantity, setProductQuantity] = useState(cartItems.length);

    useEffect(() => {
        setProductQuantity(cartItems.length)
    }, [cartItems]);

    const headerRef = useRef(null);
    const menuLeft = useRef(null);
    const checkVipRef = useRef(null);

    const menuToggle = () => menuLeft.current.classList.toggle('active');

    useEffect(() => {
        window.onclick = (e) => {
            if (menuLeft?.current?.classList[1]) {
                const btnClose = document.querySelector('.header__menu__mobile-toggle');
                if (e.target !== menuLeft.current && e.target.closest('.header__menu__mobile-toggle') !== btnClose) {
                    menuLeft.current.classList.remove('active');
                }
            }
        }
    }, [])


    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                document.querySelector('.header').classList.add('shrink');
                // document.querySelector('.header__logo__card').classList.add('active');
            } else {
                document.querySelector('.header').classList.remove('shrink');
                // document.querySelector('.header__logo__card').classList.remove('active');
            }
        })
        return () => {
            window.removeEventListener('scroll');
        }
    }, [])

    // useEffect(() => {
    //     if (history.location.pathname === '/cart' || history.location.pathname === '/checkout' || history.location.pathname === '/admin') {
    //         if (document.querySelector('.header__logo__card'))
    //             document.querySelector('.header__logo__card').classList.add('active');
    //     } else {
    //         if (document.querySelector('.header__logo__card'))
    //             document.querySelector('.header__logo__card').classList.remove('active');
    //     }
    // })

    //policy list 
    useEffect(() => {
        const loadPolicy = async () => {
            try {
                dispatch(PolicyActions.getPolicy())
                dispatch(LoadingActions.setLoading())
                const res = await axios.get(`${API_URL}/policy`)
                if (res.data.success) {
                    dispatch(PolicyActions.getPolicySuccess(res.data.policy))
                    dispatch(LoadingActions.loadingSuccess())
                }
            } catch (error) {
                console.log(error);
            }
        }
        loadPolicy();
    }, [dispatch])


    const products = useSelector(state => state.productList.product)

    const getProductBySlug = (slug) => products.find(e => e.slug === slug)


    const getCartItemsInfo = (cartItems) => {
        let res = []
        if (cartItems.length > 0) {
            cartItems.forEach(e => {
                let product = getProductBySlug(e.slug)
                res.push({
                    ...e,
                    product: product
                })
            })
        }
        return res.sort((a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0))
    }

    const priceShip = 200000;
    const cartProducts = getCartItemsInfo(cartItems);
    let cartTotalPrice = cartItems.reduce((total, item) => total + (Number(item.price)) * Number(item.quantity), 0)

    const receiverPolicy = policy !== null && policy.filter(x => x.price < cartTotalPrice);
    const discountPrice = Number(receiverPolicy[receiverPolicy.length - 1]?.content) || 0;

    cartTotalPrice = cartTotalPrice < priceShip ? cartTotalPrice + 30000 : (cartTotalPrice * (100 - discountPrice) / 100)

    const handleCheckVip = () => {
        const phone = checkVipRef.current.value;

        const checkPhone = async () => {
            dispatch(LoadingActions.setLoading());
            const res = await axios.get(`${API_URL}/phone/${phone}`)
            if (res.data.success) {
                dispatch(LoadingActions.loadingSuccess());
                alert(`Chúc mừng! hiện bạn đang là khách hàng Vip${res.data.phone.level} của chúng tôi. Mức ưu đãi hiện tại là ${res.data.phone.discount}%.`)
            } else {
                dispatch(LoadingActions.loadingSuccess());
            }
        }

        if (phone.trim().length > 9) {
            checkPhone();
        }
    }
    return (
        <div className="header" ref={headerRef}>
            <div className="container">
                <div className="header__logo">
                    <Link to="/">
                        <img src={logo} alt="" />
                    </Link>
                </div>
                <div className="header__menu">
                    <div className="header__menu__mobile-toggle" onClick={menuToggle}>
                        <i className="bx bx-menu-alt-left"></i>
                    </div>
                    <div className="header__menu__left" ref={menuLeft}>
                        <div className="header__menu__left__close" onClick={menuToggle}>
                            <i className="bx bx-chevron-left"></i>
                        </div>
                        {
                            mainNav.map((nav, index) => (
                                <div
                                    key={index}
                                    className={`header__menu__item header__menu__left__item ${nav.path === pathname ? 'active' : ''}`}
                                    onClick={menuToggle}
                                >
                                    <Link to={nav.path}>
                                        <span>{nav.display}</span>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                    <div className="header__menu__right">
                        <div className="header__menu__item header__menu__right__item">
                            <input ref={checkVipRef} className="header__menu__right__item__input" placeholder='Kiểm tra SĐT của tôi' />
                            <div onClick={() => handleCheckVip()} className="header__menu__right__item__button"><i className="bx bx-search"></i></div>
                        </div>
                        {/* <div className="header__menu__item header__menu__right__item">
                            <a href="tel:084 995 5678">
                                <i className='bx bx-phone-call'></i>
                                <div className="header__menu__right__item__call">
                                    <p>Tư vấn</p>
                                    <p>084 9955 678</p>
                                </div>
                            </a>
                        </div> */}
                        <div className="header__menu__item header__menu__right__item">
                            <Link to="/cart">
                                {/* <i className="bx bx-shopping-bag"></i> */}
                                <img className='header__menu__right__item__cart' src="../shopping-cart.png" alt="" />
                                <div className="header__menu__right__item__quantity">
                                    {productQuantity}
                                </div>
                            </Link>
                            {/* <Link className='header__menu__right__item__cart' to="/cart">
                                <span>Giỏ hàng</span>
                            </Link> */}
                            {
                                products.length > 0 && cartProducts.length > 0 && <div className="header__menu__right__item__card">
                                    {
                                        cartProducts.map((item, index) => (
                                            <div key={index} className='header__menu__right__item__card__item'>
                                                <div className="header__menu__right__item__card__item__image">
                                                    <img src={item.product.image01} alt="" />
                                                </div>
                                                <div className="header__menu__right__item__card__item__info">
                                                    <div className="header__menu__right__item__card__item__info__name">
                                                        <Link to={`/catalog/${item.product.slug}`}>
                                                            {item.product.title}
                                                        </Link>
                                                    </div>
                                                    <div className="header__menu__right__item__card__item__info__quantity">
                                                        - Sl : {item.quantity}
                                                    </div>
                                                    <div className="header__menu__right__item__card__item__info__price">
                                                        - Giá : <strong>{numberWithComas(Number(item.product.price) * Number(item.quantity) / 1000)}k </strong>
                                                    </div>
                                                    <div className="header__menu__right__item__card__item__info__del" onClick={() => dispatch(removeItem(item))}>
                                                        <i className="bx bx-trash"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="header__menu__right__item__card__btn">
                                        <Link to="/checkout">
                                            <p>Đặt hàng</p>: {numberWithComas(Number(cartTotalPrice))} vnđ
                                            <p>{cartTotalPrice - 30000 >= priceShip && (discountPrice > 0 ? `(-${numberWithComas(discountPrice)}%)` : '(Freeship)')}</p>
                                        </Link>
                                    </div>
                                </div>
                            }
                        </div>
                        {/* <div className="header__menu__item header__menu__right__item">
                            <Link to="/admin">
                            <i className="bx bx-user"></i>
                            </Link>
                            <div className="header__menu__right__item__content">
                                <span><strong>Khánh Xuân</strong></span>
                                <span><strong>Logout</strong></span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Header
