import React from 'react'
import Helmet from '../components/Helmet'

const About = () => {
    return (
        <Helmet title='Giới thiệu'>
            <div className="about">
                <h1>Mũm - Thời trang cho giới trẻ</h1>
                <div className="about__content">
                    <div className="about__content__img">
                        <img src="../about-1.jpg" alt="" />
                    </div>
                    <div className="about__content__desc">
                        <h2> </h2>
                        <p>Ở thời đại 4.0, thời trang không phải là mặc cho cho có, mặc cho xong mà mặc để tôn lên vẻ đẹp của bản thân và giá trị của con người bạn. Thấu hiểu điều đó, với châm ngôn 'Love yourself, Love yoursize", MŨMBIGSIZE mang tới cho phụ nữ nói chung và chị em BIGSIZE nói riêng trải nghiệm mua sắm tuyệt vời. Từ phong cách street style mang hơi hướng tự do, cá tính đến phong cách lịch sự, văn phòng, đảm bảo chị em sẽ có những thay đổi tích cực trong phong cách thời trang cũng như cảm nhận, trận trong và yêu bản thân mình.</p>
                    </div>
                </div>

                {/* <div className="about__content">
                    <div className="about__content__desc">
                        <h2>PHONG CÁCH VÀ TIÊU CHÍ CỦA 714 Street?</h2>
                        <p>Khó có thể chỉ ra một đặc trưng cụ thể của phong cách thời trang đường phố vì nó được kết hợp từ nhiều phong cách thời trang khác nhau dựa trên gu ăn mặc, thẩm mỹ của mỗi cá nhân. 714 Street đề cao sự tối giản nhưng tinh tế, đường phố cổ điển nhưng không lỗi thời, không màu mè nhưng vẫn cá tính.</p>
                    </div>
                    <div className="about__content__img">
                        <img src="../about-2.png" alt="" />
                    </div>
                </div>

                <div className="about__content">
                    <div className="about__content__img">
                        <img src="../about-3.jpg" alt="" />
                    </div>
                    <div className="about__content__desc">
                        <h2>714 Street THỎA MÃN NHỮNG VỊ KHÁCH KHÓ TÍNH NHẤT</h2>
                        <p>Với tiêu chí đơn giản, tinh tế, cá tính & "chất" chúng tôi luôn mang đến những sản phẩm đẹp, chât lượng, dễ phối đồ. Đặc biệt không lòe loẹt, màu mè nên vẫn giữ được sự lịch thiệp, trang trọng khi cần thiết!!</p>
                    </div>
                </div> */}
            </div>
        </Helmet >
    )
}

export default About