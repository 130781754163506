import React, { useCallback, useEffect, useRef, useState } from 'react';

import Helmet from '../components/Helmet';
import CheckBox from '../components/CheckBox';

// import productData from '../assets/fake-data/product';
import category from '../assets/fake-data/catagory';
import colors from '../assets/fake-data/product-color';
import sizes from '../assets/fake-data/product-size';
import Button from '../components/Button';
import InfinityList from '../components/InfinityList';
import { useSelector } from 'react-redux';

const Catalog = () => {
    const filterRef = useRef(null);
    const initFilter = {
        category: [],
        size: [],
        color: [],
    }
    const productList = useSelector(state => state.productList.product)

    // const productList = productData.getAllProducts();

    const [filter, setFilter] = useState(initFilter);
    const [products, setProducts] = useState(productList);

    const filterSelect = (type, checked, item) => {
        if (checked) {
            switch (type) {
                case "CATEGORY":
                    setFilter({ ...filter, category: [...filter.category, item.categorySlug] })
                    break;
                case "COLOR":
                    setFilter({ ...filter, color: [...filter.color, item.color] })
                    break;
                case "SIZE":
                    setFilter({ ...filter, size: [...filter.size, item.size] })
                    break;
                default:
                    break;
            }
        } else {
            switch (type) {
                case "CATEGORY":
                    const newCategory = filter.category.filter(e => e !== item.categorySlug);
                    setFilter({ ...filter, category: newCategory })
                    break;
                case "COLOR":
                    const newColor = filter.color.filter(e => e !== item.color);
                    setFilter({ ...filter, color: newColor })
                    break;
                case "SIZE":
                    const newSize = filter.size.filter(e => e !== item.size);
                    setFilter({ ...filter, size: newSize })
                    break;
                default:
                    break;
            }
        }
    }

    const clearFilter = () => {
        setFilter(initFilter);
    }

    const updateProducts = useCallback(
        () => {
            let newProducts = productList;

            if (filter.category.length > 0) {
                newProducts = newProducts.filter(product => filter.category.includes(product.categorySlug));
            }
            if (filter.color.length > 0) {
                newProducts = newProducts.filter(product => {
                    const check = product.colors.find(color => filter.color.includes(color));
                    return check !== undefined;
                })
            }
            if (filter.size.length > 0) {
                newProducts = newProducts.filter(product => {
                    const check = product.size.find(size => filter.size.includes(size));
                    return check !== undefined;
                })
            }
            setProducts(newProducts);
        },
        [filter, productList],
    )

    useEffect(() => {
        window.scrollTo(0, 0);
        updateProducts();
    }, [updateProducts])

    const showFilter = () => filterRef.current.classList.toggle('active');

    return (
        <Helmet title="Sản phẩm">
            <div className="catalog">
                <div className="catalog__filter" ref={filterRef}>
                    <div className="catalog__filter__close">
                        <i className="bx bx-left-arrow-alt" onClick={showFilter}></i>
                    </div>
                    <div className="catalog__filter__widget">
                        <div className="catalog__filter__widget__title">
                            Danh mục sản phẩm
                        </div>
                        <div className="catalog__filter__widget__content">
                            {
                                category.map((item, index) => (
                                    <div key={index} className="catalog__filter__widget__content__item" >
                                        <CheckBox
                                            label={item.display}
                                            onChange={(input) => filterSelect("CATEGORY", input.checked, item)}
                                            checked={filter.category.includes(item.categorySlug)}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="catalog__filter__widget">
                        <div className="catalog__filter__widget__title">
                            Màu sắc
                        </div>
                        <div className="catalog__filter__widget__content">
                            {
                                colors.map((item, index) => (

                                    <div key={index} className="catalog__filter__widget__content__item" >
                                        <CheckBox
                                            label={item.display}
                                            onChange={(input) => filterSelect("COLOR", input.checked, item)}
                                            checked={filter.color.includes(item.color)}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="catalog__filter__widget">
                        <div className="catalog__filter__widget__title">
                            Kích cỡ
                        </div>
                        <div className="catalog__filter__widget__content">
                            {
                                sizes.map((item, index) => (

                                    <div key={index} className="catalog__filter__widget__content__item" >
                                        <CheckBox
                                            label={item.display}
                                            onChange={(input) => filterSelect("SIZE", input.checked, item)}
                                            checked={filter.size.includes(item.size)}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="catalog__filter__widget">
                        <div className="catalog__filter__widget__content">
                            <Button
                                size="sm"
                                onClick={clearFilter}
                            >
                                Xoá bộ lọc
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="catalog__filter__toggle">
                    <Button size="sm" onClick={showFilter}>Bộ lọc</Button>
                </div>
                <div className="catalog__content">
                    {
                        products.length > 0 ? (
                            <InfinityList data={products} />
                        ) : <h1>Không có sản phẩm nào phù hợp</h1>
                    }
                </div>
            </div>
        </Helmet >
    );
};

export default Catalog;
