const size = [
    {
        display: "M",
        size: "m"
    },
    {
        display: "L",
        size: "l"
    },
    {
        display: "XL",
        size: "xl"
    },
    {
        display: "2XL",
        size: "2xl"
    },
    {
        display: "3XL",
        size: "3xl"
    },
    {
        display: "4XL",
        size: "4xl"
    },
    {
        display: "5XL",
        size: "5xl"
    },
    {
        display: "6XL",
        size: "6xl"
    },
    {
        display: "7XL",
        size: "7xl"
    },
]

export default size