import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminActions } from '../redux/admin/adminSlice';
import numberWithComas from '../utils/numberWithComas';
import Button from './Button';
import CustomerForm from './CustomerForm';
import EditOrderProduct from './EditOrderProduct';

const EditOrder = () => {
    const dispatch = useDispatch()

    const priceShip = 200000;

    const order = useSelector(state => state.admin.order);

    const orderItem = useSelector(state => state.admin.orderItem) || [];

    let totalPrice = orderItem.reduce((total, item) => total += item.price * item.quantity, 0)

    const policys = useSelector(state => state.policys.policys)

    const receiverPolicy = policys !== null && policys.filter(x => x.price < totalPrice);
    const discountPrice = Number(receiverPolicy[receiverPolicy.length - 1]?.content) || 0;
    totalPrice = totalPrice >= priceShip ? totalPrice * (100 - discountPrice) / 100 : totalPrice + 30000;

    return (
        <div className={`order-view__modal ${order ? 'active' : ''}`}>
            <div className="order-view__modal__content">
                <div className="cart__list">
                    {
                        orderItem.map((item, index) => (
                            <EditOrderProduct key={index} item={item} />
                        ))
                    }
                </div>
                {
                    orderItem && <div className="cart__info__txt">
                        <div className="cart__info__txt__price">
                            <span>Thành tiền</span>
                            <span>{numberWithComas(order.price)}</span>
                        </div>
                    </div>
                }
                <CustomerForm
                    order={order}
                />
                <div className="order-view__modal__content__close">
                    <Button
                        size="sm"
                        onClick={() => dispatch(adminActions.removeOrderEdit())}
                    >
                        đóng
                    </Button>
                </div>
            </div>

        </div>
    )
}

export default EditOrder
