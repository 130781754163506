const img1 = require("../images/cover.jpg").default
// const img2 = require("../images/slider/2.png").default
// const img3 = require("../images/slider/3.png").default

const heroSliderData = [
    {
        title: "Áo Polo Lacoste PO01",
        description: '<p><span>Chất vải cá sấu Cotton thoáng mát co dãn 4 chiều tạo sự thoải mái.<br>Công nghệ xử lý vải hàng đầu, nhanh khô, chống nhăn khi giặt.<br>Miễn phí giao hàng cho đơn hàng từ 2 sản phẩm.<br>Đổi trả miễn phí trong 15 ngày nếu không hài lòng.</span>&nbsp;</p>',
        img: img1,
        color: "xanh-duong",
        path: "/catalog/ao-thun-polo-01"
    },
    // {
    //     title: "Áo Polo Lacoste PO02",
    //     description: '<p><span>Chất vải cá sấu Cotton thoáng mát co dãn 4 chiều tạo sự thoải mái.<br>Công nghệ xử lý vải hàng đầu, nhanh khô, chống nhăn khi giặt.<br>Miễn phí giao hàng cho đơn hàng từ 2 sản phẩm.<br>Đổi trả miễn phí trong 15 ngày nếu không hài lòng.</span>&nbsp;</p>',
    //     img: img2,
    //     path: "/catalog/ao-thun-polo-02",
    //     color: "do"
    // },
    // {
    //     title: "Áo Polo Lacoste PO02",
    //     description: '<p><span>Chất vải cá sấu Cotton thoáng mát co dãn 4 chiều tạo sự thoải mái.<br>Công nghệ xử lý vải hàng đầu, nhanh khô, chống nhăn khi giặt.<br>Miễn phí giao hàng cho đơn hàng từ 2 sản phẩm.<br>Đổi trả miễn phí trong 15 ngày nếu không hài lòng.</span>&nbsp;</p>',
    //     img: img3,
    //     path: "/catalog/ao-thun-polo-02",
    //     color: "cam"
    // }
]

export default heroSliderData