import axios from 'axios'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API_URL } from '../constants/constants'
import { LoadingActions } from '../redux/loading/loadingSlice'
import { CommentActions } from '../redux/comment/commentSlice'
import { adminActions } from '../redux/admin/adminSlice'

const ListComment = () => {
    const dispatch = useDispatch()

    const comments = useSelector(state => state.comment.allComments)
    const products = useSelector(state => state.productList.product)

    useEffect(() => {
        const getListComment = async () => {
            dispatch(LoadingActions.setLoading());
            const res = await axios.get(`${API_URL}/comments`)
            if (res.data.success) {
                dispatch(CommentActions.getAllComment(res.data.comments))
                dispatch(LoadingActions.loadingSuccess());
            }
        }
        getListComment()
    }, [dispatch])

    const handleEditComment = (comment) => {
        dispatch(adminActions.setCommentEdit(comment))
        dispatch(adminActions.setShow('editComment'))
    }

    const handleDeleteComment = (id) => {
        const deleteComment = async () => {
            dispatch(LoadingActions.setLoading());
            const res = await axios.delete(`${API_URL}/comments/${id}`)
            if (res.data.success) {
                dispatch(CommentActions.deleteComment(id))
                dispatch(LoadingActions.loadingSuccess());
            }
        }
        deleteComment()
    }

    return (
        <div className="admin__content__list__order">
            <h1>Danh sách comments</h1>
            <table>
                <thead>
                    <tr>
                        <th>STT</th>
                        <th>Hình ảnh</th>
                        <th>Tên sản phẩm</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        comments && products && comments.map((item, index) => {
                            const product = products.find(x => x._id === item.productId)
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <img src={product.image01} alt="" />
                                </td>
                                <td>
                                    {product.title}
                                </td>
                                <td><i onClick={() => handleEditComment(item)} className="bx bx-edit"></i></td>
                                <td><i onClick={() => handleDeleteComment(item._id)} className="bx bx-trash"></i></td>
                            </tr>
                        })
                    }
                </tbody>
            </table>

        </div>
    )
}

export default ListComment
