import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import numberWithComas from '../utils/numberWithComas';
import { adminActions } from '../redux/admin/adminSlice';
import { useDispatch } from 'react-redux';


const EditOrderProduct = props => {
    const { item } = props;

    const quantity = item.quantity

    const dispatch = useDispatch()

    // const [quantity, setQuantity] = useState(item.quantity);

    const updateQuantity = (type) => {
        if (type === '+') {
            dispatch(adminActions.updateOrderEditProduct({ ...item, quantity: quantity + 1 }))
            // setQuantity(quantity + 1)
        }
        if (type === '-') {
            dispatch(adminActions.updateOrderEditProduct({ ...item, quantity: quantity - 1 === 0 ? 1 : quantity - 1 }))
            // setQuantity(quantity - 1 === 0 ? 1 : quantity - 1)
        }
    }

    const removeCartItem = () => {
        dispatch(adminActions.deleteOrderEditProduct(item))
    }

    return (
        <div className='cart__item'>
            <div className="cart__item__image">
                <img src={item.product.image01} alt="" />
            </div>
            <div className="cart__item__info">
                <div className="cart__item__info__name">
                    <Link to={`/catalog/${item.slug}`}>
                        {`${item.product.title} - ${item.color} - ${item.size}`}
                    </Link>
                </div>
                <div className="cart__item__info__price">
                    {numberWithComas(Number(item.price) * Number(quantity))}
                </div>
                <div className="cart__item__info__quantity">
                    <div className="product__info__item__quantity">
                        <div
                            className="product__info__item__quantity__btn"
                            onClick={() => updateQuantity('-')}
                        >
                            <i className="bx bx-minus"></i>
                        </div>
                        <div className="product__info__item__quantity__input">
                            {quantity}
                        </div>
                        <div
                            className="product__info__item__quantity__btn"
                            onClick={() => updateQuantity('+')}
                        >
                            <i className="bx bx-plus"></i>
                        </div>
                    </div>
                </div>
                <div className="cart__item__info__del" onClick={() => removeCartItem()}>
                    <i className="bx bx-trash"></i>
                </div>
            </div>
        </div>
    )
}

EditOrderProduct.propTypes = {
    item: PropTypes.object,
}

export default EditOrderProduct
