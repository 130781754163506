import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    comments: [],
    allComments: []
}

export const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {
        getComment: (state, action) => {
            state.loading = true
        },
        getAllComment: (state, action) => {
            return {
                ...state,
                loading: false,
                allComments: action.payload.reverse()
            }
        },
        getCommentSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                comments: action.payload.reverse()
            }
        },
        addComment: (state, action) => {
            state.comments = [...state.comments, action.payload]
        },
        deleteComment: (state, action) => {
            state.allComments = state.allComments.filter(cm => cm._id !== action.payload)
        },
        deleteCommentDetail: (state, action) => {
            const index = state.allComments.findIndex(x => x._id === action.payload._id)
            if (index > -1) {
                state.allComments[index] = action.payload
            }
        }
    }
})

export const CommentActions = commentSlice.actions

export default commentSlice.reducer
