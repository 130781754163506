import axios from 'axios';
import React from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../constants/constants';
import { LoadingActions } from '../redux/loading/loadingSlice';
import { PhoneActions } from '../redux/phone-list/phoneListSlice';

const EditPhone = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const phone = useSelector(state => state.admin.phone);
    const dispatch = useDispatch();

    const onSubmit = data => {
        const updatePhone = async () => {
            dispatch(LoadingActions.setLoading());
            const res = await axios.put(`${API_URL}/phone/${phone._id}`, data)
            if (res.data.success) {
                dispatch(PhoneActions.editPhone(res.data.phone))
                dispatch(LoadingActions.loadingSuccess());
                alert('Sửa sđt giảm giá thành công!')
            }
        }
        updatePhone();
    };

    return (
        <div className="add-gift">
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    placeholder="SĐT"
                    {...register("phone", { required: true })}
                    defaultValue={phone.phone}
                />
                {errors.phone && <span className='add-gift__error'>Sđt ưu đãi không được bỏ trống</span>}

                <input
                    placeholder="Tên khách hàng"
                    {...register("name", { required: true })}
                    defaultValue={phone.name}
                />
                {errors.name && <span className='add-gift__error'>Tên khách hàng không được bỏ trống</span>}

                <input
                    placeholder="Phần trăm giảm giá"
                    type="number"
                    {...register("discount", { required: true })}
                    defaultValue={phone.discount}
                />
                {errors.discount && <span className='add-gift__error'>Số tiền không được bỏ trống</span>}

                <input
                    placeholder="Cấp độ"
                    type="number"
                    {...register("level", { required: true })}
                    defaultValue={phone.level}
                />

                <button type="submit" className='btn bg-main btn-block' >Sửa SĐT giảm giá</button>
            </form>
        </div>
    )
}

export default EditPhone
