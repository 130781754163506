import React from 'react'

const SaleTag = (props) => {
    const { type } = props;
    return (
        type && <div className="tag">
            {type.includes('new') && <span className="tag__new"> NEW</span>}
            {type.includes('hot') && <span className="tag__hot"> HOT</span>}
            {type.includes('sale') && <span className="tag__sale"> SALE</span>}
            {type.includes('super-sale') && <span className="tag__sale"> SALE</span>}
        </div>
    )
}

export default SaleTag