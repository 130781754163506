import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import numberWithComas from '../utils/numberWithComas'
import { useDispatch } from 'react-redux'
import { adminActions } from '../redux/admin/adminSlice'
import { productListActions } from '../redux/product-list/productListSlice'
import { API_URL } from '../constants/constants'
import axios from 'axios'
import { LoadingActions } from '../redux/loading/loadingSlice'

const ProductListItem = props => {
    const dispatch = useDispatch();

    const handleEditProduct = () => {
        dispatch(adminActions.setProductEdit({
            type: 'editProduct',
            product: props.product
        }))
    }
    const handleDeleteProduct = () => {
        if (window.confirm('Bạn có chắc chắn muốn xóa') === true) {
            dispatch(LoadingActions.setLoading())
            axios.delete(`${API_URL}/product/${props.product._id}`)
                .then((res) => {
                    dispatch(productListActions.deleteProduct(props.product._id))
                    dispatch(LoadingActions.loadingSuccess())
                    alert('Xóa sản phẩm thành công!');
                })
                .catch((err) => {
                    console.log('err', err)
                })
        }
    }

    return (
        <div className="admin__content__list__product__item">
            <div className="admin__content__list__product__item__image">
                <img src={props.img01} alt="" />
            </div>
            <div className="cart__item__info">
                <div className="cart__item__info__name">
                    <Link to={`/catalog/${props.slug}`}>
                        {props.name}
                    </Link>
                </div>
                <div className="cart__item__info__price">
                    {numberWithComas(props.price)}
                </div>
                <div onClick={handleEditProduct} className="cart__item__info__del">
                    <i className="bx bx-edit"></i>
                </div>
                <div onClick={handleDeleteProduct} className="cart__item__info__del">
                    <i className="bx bx-trash"></i>
                </div>
            </div>
        </div >
    )
}

ProductListItem.propTypes = {
    img01: PropTypes.string.isRequired,
    img02: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
}

export default ProductListItem
