import React from 'react'
import Helmet from '../components/Helmet'
import LoginForm from '../components/LoginForm'

const Login = () => {

    return (
        <Helmet title='login'>
            <LoginForm />
        </Helmet>
    )
}

export default Login
