import React from 'react'
import Helmet from '../components/Helmet'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import numberWithComas from '../utils/numberWithComas';
// import productData from '../assets/fake-data/product';
import CustomerForm from '../components/CustomerForm';
import Policy from '../components/Policy';

const Checkout = () => {
    const priceShip = 200000;

    const policy = useSelector(state => state.policys.policys)

    const cartItems = useSelector((state) => state.cartItems.value)

    const products = useSelector(state => state.productList.product)

    const getProductBySlug = (slug) => products.find(e => e.slug === slug)

    const getCartItemsInfo = (cartItems) => {
        let res = []
        if (cartItems.length > 0) {
            cartItems.forEach(e => {
                let product = getProductBySlug(e.slug)
                res.push({
                    ...e,
                    product: product
                })
            })
        }
        return res.sort((a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0))
    }


    const cartProducts = getCartItemsInfo(cartItems);
    const totalPrice = cartItems.reduce((total, item) => total + (Number(item.price)) * Number(item.quantity), 0)
    const totalProducts = cartItems.reduce((total, item) => total + (Number(item.quantity)), 0);

    const nextPolicy = policy !== null && policy.find(x => x.price > totalPrice);
    const receiverPolicy = policy !== null && policy.filter(x => x.price < totalPrice);
    const discountPrice = Number(receiverPolicy[receiverPolicy.length - 1]?.content) || 0;

    return (
        <Helmet title='Thanh toán'>
            <div className="checkout">
                <CustomerForm
                    totalPrice={totalPrice}
                    cartProducts={cartProducts}
                    discountPrice={discountPrice}
                    nextPolicy={nextPolicy}
                    receiverPolicy={receiverPolicy}
                    totalProducts={totalProducts}
                />
                <div className="checkout__list">
                    <h2>Thông tin sản phẩm</h2>
                    {
                        products.length > 0 && cartProducts.map((item, index) => (
                            <div key={index} className='checkout__list__item'>
                                <div className="checkout__list__item__image">
                                    <img src={item.product.image01} alt="" />
                                </div>
                                <div className="checkout__list__item__info">
                                    <div className="checkout__list__item__info__name">
                                        <Link to={`/catalog/${item.product.slug}`}>
                                            {`${item.product.title} - ${item.color} - ${item.size}`}
                                        </Link>
                                    </div>
                                    <div className="checkout__list__item__info__quantity">
                                        {item.quantity}
                                    </div>
                                    <div className="checkout__list__item__info__price">
                                        {numberWithComas(Number(item.product.price) * Number(item.quantity))} VNĐ
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <Policy price={{ totalProducts, totalPrice, priceShip, nextPolicy, receiverPolicy }} />
                </div>
            </div >
        </Helmet >
    )
}


export default Checkout
