import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// import productData from '../assets/fake-data/product'

import Grid from '../components/Grid'
import Helmet from '../components/Helmet'
import ProductCard from '../components/ProductCard'
import ProductView from '../components/ProductView'
import Section, { SectionBody, SectionTitle } from '../components/Section'

const Product = props => {
    const products = useSelector(state => state.productList.product)

    const [product, setProduct] = useState(undefined);

    const getProducts = (count) => {
        const max = products.length - count
        const min = 0
        const start = Math.floor(Math.random() * (max - min) + min)
        return products.slice(start, start + count)
    }

    const relatedProducts = getProducts(8);

    useEffect(() => {
        const getProductBySlug = (slug) => products.find(e => e.slug === slug)
        setProduct(getProductBySlug(props.match.params.slug))
        window.scrollTo(0, 0);
    }, [props.match.params.slug, products])

    return (
        product ? <Helmet title={product.title}>
            <Section>
                <SectionBody>
                    <ProductView product={product} />
                </SectionBody>
            </Section>
            <Section>
                <SectionTitle>
                    Khám phá thêm
                </SectionTitle>
                <SectionBody>
                    <Grid
                        col={4}
                        mdCol={2}
                        smCol={1}
                    >
                        {
                            relatedProducts.map((item, index) => (
                                <ProductCard
                                    key={index}
                                    img01={item.image01}
                                    img02={item.image02}
                                    name={item.title}
                                    price={Number(item.price)}
                                    oldPrice={Number(item.oldPrice)}
                                    slug={item.slug}
                                    id={item._id}
                                    tag={item.tag}
                                    rating={item.rating ? item.rating : 5}
                                    sold={item.sold ? item.sold : 0}
                                >
                                </ProductCard>
                            ))
                        }
                    </Grid>
                </SectionBody>
            </Section>
        </Helmet> : ""
    )
}

export default Product
