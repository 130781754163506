import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from './Button'
import ProductView from './ProductView'

import { remove } from '../redux/product-modal/productModalSlice'

const ProductViewModal = () => {
    const products = useSelector(state => state.productList.product)

    const productSlug = useSelector((state) => state.productModal.value)

    const dispatch = useDispatch()

    const [product, setProduct] = useState(undefined);

    useEffect(() => {
        const getProductBySlug = (slug) => products.find(e => e.slug === slug)

        setProduct(getProductBySlug(productSlug))

    }, [products, productSlug]);

    useEffect(() => {
        document.addEventListener("keydown", function (e) {
            var keyCode = e.keyCode;
            if (keyCode === 27) {
                if (document.querySelector('.custom__size__modal').classList.contains('active')) {
                    document.querySelector('.custom__size__modal').classList.remove('active')
                } else {
                    document.querySelector('.product-view__modal').classList.remove('active')
                    dispatch(remove())
                }
            }
        }, false);
        document.addEventListener("click", (e) => {
            const modal = document.querySelector('.product-view__modal')
            const modalSize = document.querySelector('.custom__size__modal')
            if (e.target === modal) {
                modal.classList.remove('active')
                document.querySelector('.custom__size__modal').classList.remove('active')
                dispatch(remove())
            }
            if (e.target === modalSize) {
                if (document.querySelector('.custom__size__modal').classList.contains('active')) {
                    document.querySelector('.custom__size__modal').classList.remove('active')
                }
            }
        })
    }, [dispatch])

    return (
        <div className={`product-view__modal ${product === undefined ? '' : 'active'}`}>
            <div className="product-view__modal__content">
                <ProductView product={product} />
                <div className="product-view__modal__content__close">
                    <Button
                        size="sm"
                        onClick={() => dispatch(remove())}
                    >
                        đóng
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ProductViewModal
