import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    phones: []
}

export const phoneSlice = createSlice({
    name: 'phones',
    initialState,
    reducers: {
        getPhone: (state, action) => {
            state.loading = true
        },
        getPhoneSuccess: (state, action) => {
            return {
                loading: false,
                phones: action.payload
            }
        },
        addPhone: (state, action) => {
            state.phones = [...state.phones, action.payload]
        },
        editPhone: (state, action) => {
            const updatePhone = action.payload;
            const phoneIndex = state.phones.findIndex(x => x._id === updatePhone._id)
            if (phoneIndex >= 0) {
                state.phones[phoneIndex] = updatePhone
            }
        },
        deletePhone: (state, action) => {
            const phone = action.payload;
            state.phones = state.phones.filter(x => x.phone !== phone)
        }
    }
})

export const PhoneActions = phoneSlice.actions

export default phoneSlice.reducer
