import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Helmet from '../components/Helmet'
import { API_URL } from '../constants/constants';
import { LoadingActions } from '../redux/loading/loadingSlice';

const CheckPhone = () => {
    const { register, handleSubmit } = useForm();

    const [vip, setVip] = useState(undefined);
    const dispatch = useDispatch();

    const onSubmit = data => {
        const checkPhone = async () => {
            dispatch(LoadingActions.setLoading());
            const res = await axios.get(`${API_URL}/phone/${data.phone}`)
            if (res.data.success) {
                dispatch(LoadingActions.loadingSuccess());
                setVip(res.data.phone);
            } else {
                dispatch(LoadingActions.loadingSuccess());
                setVip(undefined);
            }
        }
        checkPhone();
    };

    return (
        <Helmet title='Check Khách hàng vip'>
            <div className="check-phone">
                <div className="check-phone__list">
                    {/* <h2>Bảng thông tin cấp độ VIP</h2>
                    <hr />
                    <table>
                        <thead>
                            <tr>
                                <td>STT</td>
                                <td>Cấp độ VIP</td>
                                <td>Mức giảm giá</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>VIP1</td>
                                <td>10%</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>VIP2</td>
                                <td>15%</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>VIP3</td>
                                <td>20%</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>VIP4</td>
                                <td>25%</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>VIP5</td>
                                <td>30%</td>
                            </tr>
                        </tbody>
                    </table> */}
                    <img src="./1.jpg" alt="" />
                </div>
                <div className="check-phone__check">
                    <h2>Kiểm tra cấp độ VIP của bạn</h2>
                    <hr />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input
                            placeholder="Nhập SĐT của bạn"
                            {...register("phone", { required: true })}
                        />
                        <button type='submit' className='btn bg-main btn-block'>Kiểm tra</button>
                    </form>

                    {
                        vip && <div className='check-phone__check__info'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>SĐT</td>
                                        <td>Tên </td>
                                        <td>Mức giảm giá</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{vip.phone}</td>
                                        <td>{vip.name}</td>
                                        <td>{vip.discount}%</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h2>Chúc mừng! hiện bạn đang là khách hàng vip của chúng tôi. Mức ưu đãi hiện tại là <strong>{vip.discount}%</strong>. </h2>
                        </div>
                    }
                </div>
            </div>
        </Helmet>
    )
}

export default CheckPhone