import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../constants/constants';
import { adminActions } from '../redux/admin/adminSlice';
import { LoadingActions } from '../redux/loading/loadingSlice';
import { OrderListActions } from '../redux/order-list/orderListSlice';
// import { deleteAllItem } from '../redux/shopping-cart/cartItemsSlice';
import ErrorMessage from './ErrorMessage';
import { useHistory } from "react-router-dom";
import TotalPrice from './TotalPrice';
import numberWithComas from '../utils/numberWithComas';

const CustomerForm = (props) => {
    const { cartProducts, totalPrice, discountPrice, nextPolicy, receiverPolicy, totalProducts } = props

    const [discount, setDiscount] = useState(0)

    const orderItem = useSelector(state => state.admin.orderItem);

    const policys = useSelector(state => state.policys.policys)

    const dispatch = useDispatch();

    const [payment, setPayment] = useState('cod');

    const history = useHistory();

    const [coupon, setCoupon] = useState(undefined)

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    let price = totalProducts >= 2 ? (totalPrice * (100 - discountPrice) / 100) : (totalPrice + 20000)

    const onSubmit = data => {
        if (props.order === undefined) {

            if (payment === 'cod') {
                setCoupon(undefined)
            }

            price = price - (discount?.sdt || 0) - (discount?.coupon || 0);

            const order = {
                ...data,
                payment,
                price,
                coupon: coupon?.code,
                product: cartProducts,
                status: 'chua xac nhan'
            }

            const createOrder = async () => {
                dispatch(LoadingActions.setLoading());
                const res = await axios.post(`${API_URL}/order`, order)
                if (res.data.success) {
                    localStorage.setItem('order', JSON.stringify(order));
                    // history.push('/thankyou');

                    const newOrder = res.data.order;

                    switch (payment) {
                        case 'cod':
                            dispatch(LoadingActions.loadingSuccess());
                            history.push('/thankyou')
                            break;
                        case 'vnpay':
                            const resCode = await axios.post(`${API_URL}/vnpay/create_payment_url`, newOrder)
                            if (resCode.data.success) {
                                dispatch(LoadingActions.loadingSuccess());
                                window.open(resCode.data.vnpUrl, '_self');
                            }
                            break;
                        case 'momo':
                            const resMomo = await axios.post(`${API_URL}/momo/create_payment_url`, newOrder)
                            if (resMomo.data.success) {
                                dispatch(LoadingActions.loadingSuccess());
                                window.open(resMomo.data.url, '_self');
                            }
                            break;
                        case 'zalopay':
                            const resZalopay = await axios.post(`${API_URL}/zalopay/create_payment_url`, newOrder)
                            if (resZalopay.data.success) {
                                dispatch(LoadingActions.loadingSuccess());
                                window.open(resZalopay.data.url, '_self');
                            }
                            break;
                        case 'payos':
                            const resPayOs = await axios.post(`${API_URL}/payos/create_payment_url`, newOrder)
                            if (resPayOs.data.success) {
                                dispatch(LoadingActions.loadingSuccess());
                                window.open(resPayOs.data.data.checkoutUrl, '_self');
                            }
                            break;
                        default:
                            break;
                    }

                    localStorage.setItem('buy', true);
                }
            }
            createOrder();
        } else {
            let newPrice = orderItem.reduce((total, item) => total += item.price * item.quantity, 0)
            const receiverPolicy = policys !== null && policys.filter(x => x.price < newPrice);
            const discountPrice = Number(receiverPolicy[receiverPolicy.length - 1]?.content) || 0;
            newPrice = newPrice - discountPrice;

            const updateOrder = {
                ...data,
                price: newPrice,
                product: orderItem,
                _id: props.order._id
            }
            const updatedOrder = async () => {
                dispatch(LoadingActions.setLoading());
                const res = await axios.put(`${API_URL}/order/${props.order._id}`, updateOrder)
                if (res.data.success) {
                    dispatch(OrderListActions.editOrder(updateOrder))
                    dispatch(adminActions.removeOrderEdit())
                    dispatch(LoadingActions.loadingSuccess());
                    alert("Sửa đơn hàng thành công.")
                }
            }
            updatedOrder();
        }
    }

    useEffect(() => {
        if (!props.order) {
            document.getElementById(payment).checked = true;
        }
    })

    const handleCheckCoupon = () => {
        const cp = watch('coupon')

        if (coupon) {
            setCoupon(undefined)
            setDiscount({
                ...discount,
                coupon: 0
            })
        } else {
            if (cp.trim().length > 0) {
                dispatch(LoadingActions.setLoading());
                const checkCoupon = async () => {
                    const res = await axios.get(`${API_URL}/coupon/${cp}`)

                    dispatch(LoadingActions.loadingSuccess());
                    if (res.data.success) {
                        if (res.data.coupon.quantity > 0) {
                            if (res.data.coupon.tagType === 'thanh-toan-truoc') {
                                if (payment === 'momo' || payment === 'vnpay' || payment === 'zalopay' || payment === 'payos') {
                                    setCoupon(res.data.coupon);
                                    setDiscount({
                                        ...discount,
                                        coupon: res.data.coupon.type === 'giam-tien' ? res.data.coupon.price : (price * res.data.coupon.price / 100)
                                    })
                                } else {
                                    alert("Mã giảm giá chỉ áp dụng cho thanh toán online!")
                                }
                            } else if (res.data.coupon.tagType === 'tat-ca') {
                                setCoupon(res.data.coupon);
                            }
                        } else {
                            alert("Mã giảm giá đã hết số lượng!")
                        }
                    } else {
                        alert("Mã giảm giá không hợp lệ!")
                    }
                }
                checkCoupon();
            }
        }
    }

    const checkPhoneRef = useRef(null);

    const handlePhoneChange = (e) => {
        var value = e.target.value;
        if (checkPhoneRef.current) {
            clearTimeout(checkPhoneRef.current)
        }

        checkPhoneRef.current = setTimeout(() => {
            const checkPhone = async () => {
                const res = await axios.get(`${API_URL}/phone/${value}`)
                if (res.data.success) {
                    setDiscount({
                        ...discount,
                        sdt: price * res.data.phone.discount / 100
                    })
                } else {
                    setDiscount({
                        ...discount,
                        sdt: 0
                    })
                }
            }
            checkPhone()
        }, 1000)
    }

    const handleChangePayment = () => {
        setPayment('cod');
        setCoupon(undefined);
        setDiscount({
            ...discount,
            coupon: 0
        })
    }

    return (
        <div className="checkout__customer">
            {
                !props.order && <h2>Hình thức thanh toán</h2>
            }
            <div className="checkout__customer__info">
                {
                    !props.order && <div className="checkout__customer__info__payment">
                        <div className="checkout__customer__info__payment__item" >
                            <label htmlFor="cod" className={payment === 'cod' ? 'checked' : ''} onClick={() => handleChangePayment()}>
                                <input type="radio" id="cod" name="fav_language" value="cod" />
                                <div className="checkout__customer__info__payment__item__content">
                                    <div className="checkout__customer__info__payment__item__content__img">
                                        <img src="https://ntlogistics.vn/tin-tuc/wp-content/uploads/2020/10/cash-on-delivery-ntlogistics-01-300x240.jpg" alt="" />
                                    </div>
                                    <div className="checkout__customer__info__payment__item__content__des">
                                        <p>Thanh toán khi nhận hàng.</p>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor="momo" className={payment === 'momo' ? 'checked' : ''} onClick={() => setPayment('momo')}>
                                <input type="radio" id="momo" name="fav_language" value="momo" />
                                <div className="checkout__customer__info__payment__item__content">
                                    <div className="checkout__customer__info__payment__item__content__img">
                                        <img src="../momo_icon.jpg" alt="" />
                                    </div>
                                    <div className="checkout__customer__info__payment__item__content__des">
                                        <p>Ví MoMo.</p>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor="payos" className={payment === 'payos' ? 'checked' : ''} onClick={() => setPayment('payos')}>
                                <input type="radio" id="payos" name="fav_language" value="payos" />
                                <div className="checkout__customer__info__payment__item__content">
                                    <div className="checkout__customer__info__payment__item__content__img">
                                        <img src="../vietqr_icon.jpg" alt="" />
                                    </div>
                                    <div className="checkout__customer__info__payment__item__content__des">
                                        <p>Chuyển khoản ngân hàng.</p>
                                    </div>
                                </div>
                            </label>
                            {/* <label htmlFor="zalopay" className={payment === 'zalopay' ? 'checked' : ''} onClick={() => setPayment('zalopay')}>
                                <input type="radio" id="zalopay" name="fav_language" value="zalopay" />
                                <div className="checkout__customer__info__payment__item__content">
                                    <div className="checkout__customer__info__payment__item__content__img">
                                        <img src="https://www.tiendauroi.com/wp-content/uploads/2020/02/zalopay.png" alt="" />

                                    </div>
                                    <div className="checkout__customer__info__payment__item__content__des">
                                        <p>Ví điện tử Zalo Pay.</p>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor="vnpay" className={payment === 'vnpay' ? 'checked' : ''} onClick={() => setPayment('vnpay')}>
                                <input type="radio" id="vnpay" name="fav_language" value="vnpay" />
                                <div className="checkout__customer__info__payment__item__content">
                                    <div className="checkout__customer__info__payment__item__content__img">
                                        <img src="https://2178994764-files.gitbook.io/~/files/v0/b/gitbook-legacy-files/o/assets%2F-M3-IU1k8a0MMGt6Gmx-%2F-M_A-izB5HIWzlsGsLNH%2F-M_A02h28079MMLy2nbo%2FLogo-VNPAYQR-update.png?alt=media&token=01ee2488-e973-43d5-8ab2-7a137e031e02" alt="" />
                                    </div>
                                    <div className="checkout__customer__info__payment__item__content__des">
                                        {<p>Thẻ ATM/Internet Banking.</p>}
                                        {<p>Thẻ tín dụng(Credit card) / Thẻ ghi nợ (Debit card).</p>}
                                        <p>Quét VNPay QR.</p>
                                    </div>
                                </div>
                            </label > */}
                        </div >
                    </div >
                }
                <h2> Thông tin khách hàng</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="checkout__customer__info__name">
                        {/* <h3>Tên: </h3> */}
                        <input
                            defaultValue={props.order ? props.order.name : ''}
                            {...register("name", { required: true })}
                            placeholder="Họ và tên"
                            className={errors.name ? 'error' : ''}
                        />
                        {errors.name && <ErrorMessage error={errors.name.type} label="Tên" />}
                    </div>
                    <div className="checkout__customer__info__sdt">
                        {/* <h3>SĐT: </h3> */}
                        <input
                            defaultValue={props.order ? props.order.sdt : ''}
                            {...register("sdt", { required: true, minLength: 10, maxLength: 11 })}
                            placeholder="Số điện thoại"
                            className={errors.sdt ? 'error' : ''}
                            onChange={handlePhoneChange}
                        />
                        {errors.sdt && <ErrorMessage error={errors.sdt.type} label="SĐT" />}
                        {/* {errors.sdt && <span>Bạn chưa nhập SĐT</span>} */}
                    </div>
                    <div className="checkout__customer__info__add">
                        {/* <h3>Địa chỉ: </h3> */}
                        <input
                            defaultValue={props.order ? props.order.add : ''}
                            {...register("add", { required: true })}
                            placeholder="Địa chỉ của bạn"
                            className={errors.add ? 'error' : ''}
                        />
                        {errors.add && <ErrorMessage error={errors.add.type} label="Địa chỉ" />}

                        {/* {errors.add && <span>Bạn chưa nhập Địa chỉ</span>} */}
                    </div>
                    {/* <div className="checkout__customer__info__email">
                        <input
                            defaultValue={props.order ? props.order.email : ''}
                            {...register("email")}
                            placeholder="Địa chỉ mail của bạn"
                        />
                    </div> */}
                    <div className="checkout__customer__info__email">
                        {/* <h3>Mail: </h3> */}
                        <input
                            defaultValue={props.order ? props.order.description : ''}
                            {...register("description")}
                            placeholder="Ghi chú thêm cho đơn hàng của bạn"
                        />
                    </div>
                    {
                        props.order && <div className="checkout__customer__info__email">
                            <select {...register("status")} defaultValue={props.order.status}>
                                <option value="da len don">Đã lên đơn</option>
                                <option value="huy">Hủy</option>
                                <option value="chua xac nhan">Chưa xác nhận</option>
                                <option value="co van de">Có vấn đề</option>
                            </select>
                        </div>
                    }
                    {/* <input type="submit" value={props.order ? 'Thay đổi' : 'Thanh toán'} className='btn bg-main btn-block' /> */}

                    {
                        !props.order && <div className="checkout__customer__info__coupon">
                            <input
                                {...register("coupon")}
                                placeholder="Mã giảm giá"
                            />
                            <p onClick={handleCheckCoupon}>{coupon ? 'Hủy' : 'Áp dụng'}</p>
                        </div>
                    }
                    {
                        coupon && coupon.quantity > 0 && <p className="checkout__customer__info__coupon__content">
                            {coupon.type === 'giam-tien' ? 'Giảm tiền : ' + numberWithComas(coupon.price) : 'Giảm : ' + numberWithComas(coupon.price) + '%'}
                        </p>
                    }

                    {!props.order && <div className="checkout__customer__info__total">
                        <TotalPrice price={{ totalProducts, totalPrice, nextPolicy, receiverPolicy, discountPrice, price, discount, coupon }} />
                    </div>}
                    <button type="submit" className='btn bg-main btn-block'>{props.order ? 'Thay đổi' : 'Thanh toán'}</button>
                </form>
            </div >
        </div >
    )
}

export default CustomerForm
