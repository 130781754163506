import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./login/loginSlice";
import productModalSlice from "./product-modal/productModalSlice";
import cartItemsSlice from "./shopping-cart/cartItemsSlice";
import adminSlice from "./admin/adminSlice";
import productListSlice from "./product-list/productListSlice";
import orderListSlice from "./order-list/orderListSlice";
import commentSlice from "./comment/commentSlice";
import loadingSlice from "./loading/loadingSlice";
import giftSlice from "./gift-list/giftListSlice";
import policySlice from "./policy-list/policyListSlice";
import imagesListSlice from "./images-list/imagesListSlice";
import couponListSlice from "./coupon-list/couponListSlice";
import phoneListSlice from "./phone-list/phoneListSlice";


export const store = configureStore({
    reducer: {
        productModal: productModalSlice,
        cartItems: cartItemsSlice,
        login: loginReducer,
        admin: adminSlice,
        productList: productListSlice,
        orderList: orderListSlice,
        comment: commentSlice,
        loading: loadingSlice,
        gifts: giftSlice,
        policys: policySlice,
        imagesList: imagesListSlice,
        couponList: couponListSlice,
        phoneList: phoneListSlice
    }
})