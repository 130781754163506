import { ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Editor } from "react-draft-wysiwyg";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { LoadingActions } from '../redux/loading/loadingSlice';
import { API_URL } from '../constants/constants';
import { GiftActions } from '../redux/gift-list/giftListSlice';

const AddGift = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const dispatch = useDispatch();

    //text-edittor
    let _contentState = ContentState.createFromText('Sample content state');

    const raw = convertToRaw(_contentState)

    const [contentState, setContentState] = useState(raw)


    const onSubmit = data => {
        const content = draftToHtml(contentState);
        const gift = { ...data, content }

        const createGift = async () => {
            dispatch(LoadingActions.setLoading());
            const res = await axios.post(`${API_URL}/gift/${data.productSlug}`, gift)
            if (res.data.success) {
                dispatch(LoadingActions.loadingSuccess());
                dispatch(GiftActions.addGift(res.data.newGift));
                alert('Thêm khuyến mãi thành công!')
            }
        }
        createGift();
    };



    return (
        <div className="add-gift">
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    defaultValue="Nhãn của sản phẩm"
                    {...register("productSlug", { required: true })}
                />
                {errors.productSlug && <span className='admin__content__add__product__error'>Nhãn sản phẩm không được bỏ trống</span>}

                <input
                    type="number"
                    defaultValue={1}
                    {...register("quantity", { required: true })}
                />
                {errors.quantity && <span className='admin__content__add__product__error'>Số lượng không được bỏ trống</span>}

                <Editor
                    defaultContentState={contentState}
                    onContentStateChange={setContentState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                />

                <button type="submit" className='btn bg-main btn-block' >Thêm khuyến mãi</button>
            </form>
        </div>
    )
}

export default AddGift
