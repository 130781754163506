import React from 'react'
import PropTypes from 'prop-types'


const HeroSlider = props => {
    const data = props.data;

    return (
        <div className="hero-slider">
            {
                data.map((item, index) => (
                    <HeroSliderItem
                        key={index}
                        item={item}
                    />
                ))
            }
        </div>
    )
}

HeroSlider.propTypes = {
    data: PropTypes.array.isRequired,
    control: PropTypes.bool,
    auto: PropTypes.bool,
    timeOut: PropTypes.number,
}

const HeroSliderItem = props => (
    <div className={`hero-slider__item ${props.active ? 'active' : ''}`}>
        <div className="hero-slider__item__image">
            <img src={props.item.img} alt="" />
        </div>
    </div>
)

export default HeroSlider
