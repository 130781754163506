import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import Helmet from '../components/Helmet'
import { deleteAllItem } from '../redux/shopping-cart/cartItemsSlice';
import numberWithComas from '../utils/numberWithComas';


const Success = () => {
    const dispatch = useDispatch();

    const order = JSON.parse(localStorage.getItem('order')) || null;
    const totalProducts = order.product.reduce((total, item) => total + (Number(item.quantity)), 0)

    useEffect(() => {
        dispatch(deleteAllItem())
    }, [dispatch])

    return (
        <Helmet title='Thành công' className="thankyou">
            <div className="thankyou__content">
                <div className="thankyou__content__title">
                    <h1>Mum big xin trân thành cảm ơn quý khách.</h1>
                    {
                        order && <>
                            <h2>Quý khách vừa đặt hàng thành công tại <a href="/">dabase.store</a>.</h2>
                            <div className="thankyou__content__infor">
                                <div className="thankyou__content__infor__customer">
                                    <span>Thông tin khách hàng</span>
                                    <hr />
                                    <div className="thankyou__content__infor__customer__item">
                                        <span>Họ tên:</span>
                                        <p>{order.name}</p>
                                    </div>
                                    <div className="thankyou__content__infor__customer__item">
                                        <span>Số điện thoại:</span>
                                        <p>{order.sdt}</p>
                                    </div>
                                    <div className="thankyou__content__infor__customer__item">
                                        <span>Địa chỉ:</span>
                                        <p>{order.add}</p>
                                    </div>
                                    <div className="thankyou__content__infor__customer__item">
                                        <span>Email:</span>
                                        <p>{order.email}</p>
                                    </div>
                                    <div className="thankyou__content__infor__customer__item">
                                        <span>Ghi chú:</span>
                                        <p>{order.description}</p>
                                    </div>
                                    <div className="thankyou__content__infor__customer__check">
                                        <hr />
                                        <h3>Cập nhật thôn tin đơn hàng.</h3>
                                        <p>Bạn có thể cập nhật thông tin đơn hàng của mình qua tin nhắn Messenger ngay tại đây.</p>
                                        <a href="https://m.me/226224323910922" target="_blank" rel="noreferrer">
                                            <img src=".././mess_icon.png" alt="" />
                                            <p>Gửi tin nhắn cho tôi!</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="thankyou__content__infor__product">
                                    <span>Thông tin sản phẩm</span>
                                    <hr />
                                    {
                                        order.product.map((item, index) => (
                                            <div key={index} className='checkout__list__item'>
                                                <div className="checkout__list__item__image">
                                                    <img src={item.product.image01} alt="" />
                                                </div>
                                                <div className="checkout__list__item__info">
                                                    <div className="checkout__list__item__info__name">
                                                        <Link to={`/catalog/${item.product.slug}`}>
                                                            {`${item.product.title} - ${item.color} - ${item.size}`}
                                                        </Link>
                                                    </div>
                                                    <div className="checkout__list__item__info__quantity">
                                                        {item.quantity}
                                                    </div>
                                                    <div className="checkout__list__item__info__price">
                                                        {numberWithComas(Number(item.product.price) * Number(item.quantity))} VNĐ
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className={`cart__info__txt__price cart__info__txt__price__ship ${totalProducts >= 2 ? 'del' : ''}`}>
                                        <span>Phí vận chuyển</span>
                                        <span>{numberWithComas(20000)}</span>
                                    </div>
                                    <div className="checkout__list__total">
                                        <h2>Tổng tiền:</h2>
                                        <div className="checkout__list__total__price">
                                            {numberWithComas(Number(order.price))} VNĐ
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className="thankyou__content__btn">
                    <Link to="/">Quay lại trang chủ</Link>
                    <Link to="/catalog">Tiếp tục mua hàng</Link>
                </div>
            </div>

        </Helmet>
    )
}

export default Success
