import axios from 'axios';
import React from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../constants/constants';
import { CouponActions } from '../redux/coupon-list/couponListSlice';
import { LoadingActions } from '../redux/loading/loadingSlice';

const EditCoupon = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const coupon = useSelector(state => state.admin.coupon);

    const dispatch = useDispatch();

    const onSubmit = data => {
        const updateCoupon = async () => {
            dispatch(LoadingActions.setLoading());
            const res = await axios.post(`${API_URL}/coupon/${coupon._id}`, data)
            if (res.data.success) {
                dispatch(CouponActions.editCoupon(res.data.coupon))
                dispatch(LoadingActions.loadingSuccess());
                alert('Sửa mã giảm giá thành công!')
            }
        }
        updateCoupon();
    };

    return (
        <div className="add-gift">
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    placeholder="Mã ưu đãi"
                    {...register("code", { required: true })}
                    defaultValue={coupon.code}
                />
                {errors.code && <span className='add-gift__error'>Mã ưu đãi không được bỏ trống</span>}

                <input
                    placeholder="Số lượng"
                    {...register("quantity", { required: true })}
                    defaultValue={coupon.quantity}
                />
                {errors.quantity && <span className='add-gift__error'>Số lượng không được bỏ trống</span>}

                <select {...register("type")} defaultValue={coupon.type}>
                    <option value="phan-tram">Phần trăm</option>
                    <option value="giam-tien">Giảm giá</option>
                </select>

                <input
                    type="text"
                    placeholder="Số tiền muốn giảm"
                    {...register("price", { required: true })}
                    defaultValue={coupon.price}
                />
                {errors.price && <span className='add-gift__error'>Số tiền hoặc phần trăm không được bỏ trống</span>}

                <select {...register("tagType")} defaultValue={coupon.tagType}>
                    <option value="tat-ca">Tất cả</option>
                    <option value="thanh-toan-truoc">Thanh toán trước</option>
                    <option value="tag-san-pham">Tag sản phẩm</option>
                    <option value="thiet-ke">Thiết kế</option>
                </select>

                {
                    watch("tagType") === "tag-san-pham" && <input
                        type="text"
                        placeholder="Nội dung tag sản phẩm"
                        {...register("contentType")}
                        defaultValue={coupon.contentType}
                    />
                }

                <button type="submit" className='btn bg-main btn-block' >Sửa mã giảm giá</button>
            </form>
        </div>
    )
}

export default EditCoupon
