const category = [
    {
        display: "Áo chống nắng Bigsize",
        categorySlug: "ao-chong-nang-big-size"
    },
    {
        display: "Sweater",
        categorySlug: "sweater"
    },
    {
        display: "Hoodie",
        categorySlug: "hoodie"
    },
    {
        display: "Set đồ bộ thu đông",
        categorySlug: "set-thu-dong"
    },
    {
        display: "Set đồ bộ hè",
        categorySlug: "set-he"
    },
    {
        display: "Áo/váy polo",
        categorySlug: "ao-vay-polo"
    },
    {
        display: "Chân váy",
        categorySlug: "chan-vay"
    },
    {
        display: "Jeans",
        categorySlug: "quan-jeans"
    },
    {
        display: "Quần nỉ",
        categorySlug: "quan-ni"
    },
    {
        display: "Váy/đầm",
        categorySlug: "vay-dam-thu-dong"
    },
]

export default category