import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingActions } from '../redux/loading/loadingSlice';
import axios from 'axios';
import { CommentActions } from '../redux/comment/commentSlice';
import { API_URL } from '../constants/constants';
import { adminActions } from '../redux/admin/adminSlice';

function EditComment() {
    const comment = useSelector(state => state.admin.comment)
    const dispatch = useDispatch();
    const handleDeleteComment = (commentId, commentDetailId) => {
        const deleteComment = async () => {
            dispatch(LoadingActions.setLoading());
            const res = await axios.delete(`${API_URL}/comments`, {
                data: {
                    commentId,
                    commentDetailId
                }
            })
            if (res.data.success) {
                const updateComment = {
                    ...comment,
                    comments: comment.comments.filter(x => x._id !== commentDetailId)
                }
                dispatch(CommentActions.deleteCommentDetail(updateComment))
                dispatch(adminActions.setCommentEdit(updateComment))
                dispatch(LoadingActions.loadingSuccess());
            }
        }
        deleteComment()
    }
    return (
        <div className="admin__content__list__order">
            <h1>Danh sách comments</h1>
            <table>
                <thead>
                    <tr>
                        <th>STT</th>
                        <th>Tên khách hàng</th>
                        <th>Nội dung</th>
                        <th>Hình ảnh</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        comment && comment.comments.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>
                                    {item.comment}
                                </td>
                                <td>
                                    <img src={item.image01} alt="" />
                                </td>
                                <td><i onClick={() => handleDeleteComment(comment._id, item._id)} className="bx bx-trash"></i></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

        </div>
    )
}

export default EditComment
