import React from 'react'
import { useEffect } from 'react';

export const PreviewImage = (props) => {
    useEffect(() => {
        document.addEventListener("keydown", function (e) {
            var keyCode = e.keyCode;
            if (keyCode === 27) {
                props.setShow(false)
            }
        }, false);
    }, [props])

    return (
        <div className={`preview-image ${props.show ? 'active' : ''}`}>
            <div className="preview-image__close" onClick={() => props.setShow(false)}>
                <i className='bx bx-x'></i>
            </div>
            <div className="preview-image__image">
                <img src={props.image} alt="" />
            </div>
        </div>
    )
}
