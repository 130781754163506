import axios from 'axios';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { API_URL } from '../constants/constants';
import { adminActions } from '../redux/admin/adminSlice';
import { GiftActions } from '../redux/gift-list/giftListSlice';
import { LoadingActions } from '../redux/loading/loadingSlice';

const GiftListItem = (props) => {
    const dispatch = useDispatch()
    const products = useSelector(state => state.productList.product)

    const getProductBySlug = (slug) => products.find(e => e.slug === slug)

    const productBySlug = getProductBySlug(props.gift.productSlug);

    const handleEditProduct = () => {
        dispatch(adminActions.setGift({
            type: 'editGift',
            gift: props.gift
        }))
    }

    const handleDeleteProduct = () => {
        if (window.confirm('Bạn có chắc chắn muốn xóa đơn hàng này!')) {
            const deleteGift = async () => {
                dispatch(LoadingActions.setLoading())
                const res = await axios.delete(`${API_URL}/gift/${props.gift._id}`)
                if (res.data.success) {
                    dispatch(GiftActions.deleteGift(props.gift._id))
                    dispatch(LoadingActions.loadingSuccess())
                    alert('Xóa khuyến mãi thành công!')
                }
            }
            deleteGift()
        }
    }

    return (
        <div className="admin__content__list__product__item">
            <div className="admin__content__list__product__item__image">
                <img src={productBySlug.image01} alt="" />
                <img src={productBySlug.image02} alt="" />
            </div>
            <div className="cart__item__info">
                <div className="cart__item__info__name">
                    <Link to={`/catalog/${productBySlug.slug}`}>
                        {productBySlug.title}
                    </Link>
                </div>
                <div className="cart__item__info__quantity">
                    số lượng : {props.gift.quantity}
                </div>
                <div className="cart__item__info__content" dangerouslySetInnerHTML={{ __html: props.gift.content }}>

                </div>
                <div onClick={handleEditProduct} className="cart__item__info__del">
                    <i className="bx bx-edit"></i>
                </div>
                <div onClick={handleDeleteProduct} className="cart__item__info__del">
                    <i className="bx bx-trash"></i>
                </div>
            </div>
        </div >
    )
}

export default GiftListItem
