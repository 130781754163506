import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    loading: false,
    images: []
}

const imagesListSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {
        getImages: (state, action) => {
            state.loading = true;
        },
        getImagesSuccess: (state, action) => {
            state.loading = false;
            state.images = action.payload;
        }
    }
})

export const imagesListAction = imagesListSlice.actions;

export default imagesListSlice.reducer
