import React from 'react'

const CustomChat = () => {
    const handleChatClick = () => {
        document.querySelector('.custom-chat__widget').classList.add('active');
    }

    const handleCloseChat = () => {
        document.querySelector('.custom-chat__widget').classList.remove('active');
    }

    return (
        <div className="custom-chat">
            <div onClick={handleChatClick} className='custom-chat__btn'>
                <img src=".././mess_icon.png" alt="" />
            </div>
            <div onClick={handleCloseChat} className="custom-chat__widget">
                <div className="custom-chat__widget__content">
                    <a href="https://m.me/226224323910922" target="_blank" rel="noreferrer">
                        <img src=".././mess_icon.png" alt="" />
                        <span>Chat bằng Messenger</span>
                    </a>
                    <a href="https://zalo.me/0868488884" target="_blank" rel="noreferrer">
                        <img src=".././zalo_icon.jpg" alt="" />
                        <span>Chat bằng Zalo</span>
                    </a>
                    <a href="tel:0868488884">
                        <img src=".././call_icon.png" alt="" />
                        <span>Gọi điện Hotline</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default CustomChat
