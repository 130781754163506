import React, { useRef } from 'react'
import { useEffect } from 'react'
import Grid from '../components/Grid'
import Helmet from '../components/Helmet'

const Contact = () => {
    const item1 = useRef()
    const item2 = useRef()
    useEffect(() => {
        let i1 = setInterval(() => {
            item1.current.classList.remove('active');
            item2.current.classList.add('active');
        }, 4000);
        let i2 = setInterval(() => {
            item1.current.classList.add('active');
            item2.current.classList.remove('active');
        }, 8000);

        return () => {
            clearInterval(i1)
            clearInterval(i2)
        }
    }, [])

    return (
        <Helmet title='Liên hệ'>
            <div className="contact">
                <div className="contact__slide">
                    <div className="contact__slide__item active" ref={item1}>
                        <h3>Thêm vào giỏ 2 sản phẩm để được miễn phí vận chuyển</h3>
                    </div>
                    <div className="contact__slide__item" ref={item2}>
                        <h3>Chính sách kiểm hàng và đổi hàng miễn phí</h3>
                    </div>
                </div>
                <div className="contact__content">
                    <div className="contact__content__item">
                        <h1>Liên hệ</h1>
                        <Grid
                            col={2}
                            mdCol={2}
                            smCol={1}
                            gap={20}
                        >
                            <div className="contact__content__item__item">
                                <h2>Hỗ trợ Khách hàng mua online</h2>
                                <span>Hot line: 0868 488 884</span>
                                <p>8h-22h tất cả các ngày trong tuần</p>
                                <hr />
                            </div>
                            <div className="contact__content__item__item">
                                <span>Email: mumbigsize6868@gmail.com</span>
                                <p>Địa chỉ: Văn phòng làm việc online 67 P. Phùng Khoang, Trung Văn, Hà Đông, Hà Nội, Việt Nam</p>
                                <hr />

                            </div>
                            <div className="contact__content__item__item">
                                <h2>Chăm sóc khách hàng</h2>
                                <span>Hot line: 0868 488 884</span>
                                <p>Email: mumbigsize6868@gmail.com</p>
                                <hr />
                            </div>
                            <div className="contact__content__item__item">
                                <h2>Liên hệ làm đại lý</h2>
                                <span>Điện thoại: 0868 488 884</span>
                                <p>Mrs. Hạnh</p>
                                <p>Email: mumbigsize6868@gmail.com</p>
                                <hr />
                            </div>
                        </Grid>
                    </div>
                </div>
            </div>
        </Helmet >
    )
}

export default Contact