import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import { API_URL } from '../constants/constants';
import { useDispatch } from 'react-redux';
import { OrderListActions } from '../redux/order-list/orderListSlice';
import { LoadingActions } from '../redux/loading/loadingSlice';

const StatusOrder = props => {
    const statusRef = useRef();

    const dispatch = useDispatch()

    const handleStatusChange = (e) => {
        switch (e.target.value) {
            case 'da len don':
                statusRef.current.classList.value = "green"
                break;
            case 'huy':
                statusRef.current.classList.value = "red"
                break;
            case 'chua xac nhan':
                statusRef.current.classList.value = "blue"
                break;
            case 'co van de':
                statusRef.current.classList.value = "orange"
                break;
            case 'da-thanh-toan':
                statusRef.current.classList.value = "pink"
                break;
            default:
                break;
        }
        const orderUpdate = {
            ...props.order,
            status: e.target.value
        }
        const updatedOrder = async () => {
            dispatch(LoadingActions.setLoading())
            const res = await axios.put(`${API_URL}/order/${props.order._id}`, orderUpdate)
            if (res.data.success) {
                dispatch(OrderListActions.editOrder(orderUpdate))
                dispatch(LoadingActions.loadingSuccess())
            }
        }
        updatedOrder();
    }

    return (
        <div className="order-status">
            <select
                ref={statusRef}
                onChange={(e) => handleStatusChange(e)}
                value={props.order.status}
                className={(props.order.status === 'da len don' && 'green')
                    || (props.order.status === 'huy' && 'red')
                    || (props.order.status === 'chua xac nhan' && 'blue')
                    || (props.order.status === 'co van de' && 'orange')
                    || (props.order.status === 'da-thanh-toan' && 'pink')
                }

            >
                <option style={{ color: 'white', backgroundColor: 'green' }} value="da len don">Đã lên đơn</option>
                <option style={{ color: 'white', backgroundColor: 'blue' }} value="chua xac nhan">Chưa xác nhận</option>
                <option style={{ color: 'white', backgroundColor: 'red' }} value="huy">Hủy</option>
                <option style={{ color: 'white', backgroundColor: 'orange' }} value="co van de">Có vấn đề</option>
                <option style={{ color: 'white', backgroundColor: 'pink' }} value="da-thanh-toan">Đã thanh toán</option>

            </select>
        </div>
    )
}

StatusOrder.propTypes = {
    status: PropTypes.string,
}

export default StatusOrder
