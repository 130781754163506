import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from './Grid';
import ProductCard from './ProductCard';

const InfinityList = props => {
    const listRef = useRef();
    const perLoad = 8 // số item load ra mỗi lần
    const [data, setData] = useState([]);
    const [index, setIndex] = useState(0);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        setData(props.data.slice(0, perLoad));
        setIndex(1);
    }, [props.data])

    useEffect(() => {
        const scroll = () => {
            if (listRef && listRef.current) {
                if (window.scrollY + window.innerHeight >= listRef.current.clientHeight + listRef.current.offsetTop + 200) {
                    setLoad(true);
                }
            }
        }
        window.addEventListener('scroll', scroll);
        return () => {
            window.removeEventListener('scroll', scroll);
        }
    }, [listRef])

    useEffect(() => {
        const getItems = () => {
            const pages = Math.floor(props.data.length / perLoad);
            const maxIndex = props.data.length % perLoad === 0 ? pages : pages + 1;

            if (load && index <= maxIndex) {
                const start = perLoad * index;
                const end = start + perLoad;

                setData(data.concat(props.data.slice(start, end)));
                setIndex(index + 1);
            }
        }
        getItems();
        setLoad(false);
    }, [load, index, data, props.data])


    return (
        <div ref={listRef}>
            <Grid
                col={4}
                mdCol={2}
                smCol={2}
                gap={20}
            >
                {
                    data.map((item, index) => (
                        <ProductCard
                            key={index}
                            img01={item.image01}
                            img02={item.image02}
                            name={item.title}
                            price={Number(item.price)}
                            oldPrice={Number(item.oldPrice)}
                            slug={item.slug}
                            id={item._id}
                            tag={item.tag}
                            rating={item.rating ? item.rating : 5}
                            sold={item.sold ? item.sold : 0}
                        />
                    ))
                }
            </Grid>
        </div>
    )
}

InfinityList.propTypes = {
    data: PropTypes.array.isRequired,
}

export default InfinityList
