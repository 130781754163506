import React, { useRef, useState } from 'react';
import { LoadingActions } from '../redux/loading/loadingSlice';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { API_URL } from '../constants/constants';

const ImportPhone = props => {

    const wrapperRef = useRef(null);

    const dispatch = useDispatch()

    const [fileList, setFileList] = useState(null);

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {
            setFileList(newFile);
        }
    }

    const handleUpload = () => {
        if (fileList) {
            const formData = new FormData();
            formData.append('fileImport', fileList)

            const uploadPhone = async () => {
                dispatch(LoadingActions.setLoading());
                const res = await axios.post(`${API_URL}/phone/import`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if (res.data.success) {
                    dispatch(LoadingActions.loadingSuccess());
                    alert('Upload thành công!')
                }
            }
            uploadPhone();
        }
    }

    return (
        <div>
            <div className="drop-file">
                <div
                    ref={wrapperRef}
                    className="drop-file__input"
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                >
                    <div className="drop-file__input__label">
                        <i className='bx bx-cloud-upload'></i>
                        <p>{fileList ? fileList.name : 'Drag & Drop your files here'}</p>
                    </div>
                    <input type="file" value="" onChange={onFileDrop} />
                </div>
                <button className='btn btn-sm bg-main' onClick={handleUpload}>Upload</button>
            </div>
        </div>
    );
}

export default ImportPhone;