import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { API_URL } from '../constants/constants';
import { CommentActions } from '../redux/comment/commentSlice';
import { LoadingActions } from '../redux/loading/loadingSlice';

const Rating = props => {
    const { totalRate, commentQuantity } = props;

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [rating, setRating] = useState(0);

    const validate = () => {
        const order = JSON.parse(localStorage.getItem('order'));
        if(order && order.product.findIndex(x => x.product._id === props.productId) > -1) {
            return true;
        }
        return true;
    };

    const dispatch = useDispatch();

    const onSubmit = data => {
        if (validate()) {
            const newComment = {
                ...data,
                image01: data.image01[0]?.name || undefined,
                rating
            };
            const ncm = JSON.stringify(newComment)


            const formData = new FormData();
            formData.append('image01', data.image01[0])
            formData.set('comment', ncm)

            dispatch(LoadingActions.setLoading())
            axios.post(`${API_URL}/comments/${props.productId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res) => {
                    dispatch(LoadingActions.loadingSuccess())
                    dispatch(CommentActions.addComment(res.data.newComment))
                    reset()
                    localStorage.removeItem('order')
                })
                .catch((err) => {
                    console.log('err', err)
                })
        } else {
            alert('Bạn cần mua hàng mới có thể đánh giá về sản phẩm này!')
        }
    };



    return (
        <div className="customer__rating">
            <div className="customer__rating__container">
                <div className="customer__rating__container__content">
                    <h3 className="customer__rating__container__content__title">
                        ĐÁNH GIÁ SẢN PHẨM
                    </h3>
                    <span className="customer__rating__container__content__rate">{parseFloat(totalRate).toFixed(1)}</span>
                    {
                        <div className="customer__rating__container__content__stars">
                            <i className={`bx ${totalRate > 0 ? (totalRate > 0.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                            <i className={`bx ${totalRate > 1 ? (totalRate > 1.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                            <i className={`bx ${totalRate > 2 ? (totalRate > 2.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                            <i className={`bx ${totalRate > 3 ? (totalRate > 3.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                            <i className={`bx ${totalRate >= 4 ? (totalRate > 4.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `}></i>
                        </div>
                    }
                    <span className="customer__rating__container__content__quantity">{commentQuantity} đánh giá</span>
                </div>
            </div>
            <h3>Đánh giá của bạn</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="customer__rating__infor">
                    <input
                        placeholder='Tên của bạn'
                        {...register("name")}
                    />
                    {errors.name && <span>Bạn chưa nhập tên</span>}

                    <div className="customer__rating__infor__star">
                        <i onClick={() => setRating(1)} className={`bx ${rating >= 1 ? 'bxs-star' : 'bx-star'}`} ></i>
                        <i onClick={() => setRating(2)} className={`bx ${rating >= 2 ? 'bxs-star' : 'bx-star'}`} ></i>
                        <i onClick={() => setRating(3)} className={`bx ${rating >= 3 ? 'bxs-star' : 'bx-star'}`} ></i>
                        <i onClick={() => setRating(4)} className={`bx ${rating >= 4 ? 'bxs-star' : 'bx-star'}`} ></i>
                        <i onClick={() => setRating(5)} className={`bx ${rating >= 5 ? 'bxs-star' : 'bx-star'}`} ></i>
                    </div>
                </div>
                <div className="customer__rating__image">
                    <input
                        type="file"
                        {...register("image01")}
                    />
                    {errors.image && <span>Bạn chưa có hình ảnh</span>}
                </div>

                <div className="customer__rating__comment">
                    <textarea
                        placeholder='Đánh giá và nhận xét của bạn'
                        {...register("comment", { required: true })}
                    />
                </div>
                {errors.comment && <span>Bạn chưa nhập đánh giá</span>}

                <input className='customer__rating__btn' type="submit" value="Đánh giá" />
            </form>
        </div>
    )
}


export default Rating
