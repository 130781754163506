import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import axios from 'axios'

import Button from './Button';

import numberWithComas from '../utils/numberWithComas';
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../redux/shopping-cart/cartItemsSlice';
import { remove } from '../redux/product-modal/productModalSlice';

import { API_URL } from '../constants/constants';
import Comment from './Comment';
import Rating from './Rating';
import { CommentActions } from '../redux/comment/commentSlice';
import Gift from './Gift';
import Grid from './Grid';
import { useMemo } from 'react';

const ProductView = props => {
    const commentList = useSelector(state => state.comment.comments)
    const imagesList = useSelector(state => state.imagesList.images)
    const album = imagesList.find(x => x.productId === props.product?._id)

    const dispatch = useDispatch();

    let { product } = props;

    if (product === undefined) {
        product = {
            price: 0,
            oldPrice: 0,
            title: "",
            colors: [],
            size: []
        }
    }

    const [previewImg, setPreviewImg] = useState(product.image01);

    const [expand, setExpand] = useState(false);

    const [color, setColor] = useState(undefined);

    const [size, setSize] = useState(undefined);

    const [quantity, setQuantity] = useState(1);

    const [gift, setGift] = useState(null);

    const [totalRate, setTotalRate] = useState(5);

    const test = useMemo(() => {
        if (commentList) {
            const total = commentList.reduce((t, item) =>
                t += item.rating
                , 0);
            if (total > 0) {
                setTotalRate(total / commentList.length)
            }
        }
    }, [commentList])

    useEffect(() => {
        setPreviewImg(product.image01);
        setColor(product ? product.colors[0] : undefined);
        //setSize(product);
        setQuantity(1);
        setExpand(false);
    }, [product])

    //get comment
    useEffect(() => {
        const productId = product._id
        const getComment = async () => {
            dispatch(CommentActions.getComment());
            const res = await axios.get(`${API_URL}/comments/${productId}`)
            if (res.data.success) {
                const cmt = res.data.comments?.comments || []
                dispatch(CommentActions.getCommentSuccess(cmt))
            }
        }
        getComment()
    }, [product._id, dispatch])

    //get gift
    useEffect(() => {
        const getGift = async () => {
            const res = await axios.get(`${API_URL}/gift/${product.slug}`)
            if (res.data.success) {
                setGift(res.data.gift)
            }
        }
        getGift();
    }, [product.slug])

    const updateQuantity = (type) => {
        if (type === 'plus') {
            setQuantity(quantity + 1)
        } else {
            setQuantity(quantity - 1 > 1 ? quantity - 1 : 1);
        }
    }

    const check = () => {
        if (color === undefined) {
            alert('Bạn chưa chọn màu sắc');
            return false;
        }
        if (size === undefined) {
            alert('Bạn chưa chọn kích cỡ');
            return false;
        }
        return true;
    }

    const addToCart = () => {
        if (check()) {
            dispatch(addItem({
                slug: product.slug,
                color: color,
                size: size,
                quantity: quantity,
                price: product.price
            }))
            alert("Sản phẩm đã được thêm vào giỏ hàng")
            document.querySelector('.header__menu__right__item__card')?.classList.add('active');
            setTimeout(() => {
                document.querySelector('.header__menu__right__item__card')?.classList.remove('active');
            }, 120000)
            dispatch(remove())
        }
    }

    const gotoCart = () => {
        if (check()) {
            dispatch(addItem({
                slug: product.slug,
                color: color,
                size: size,
                quantity: quantity,
                price: product.price
            }))
            props.history.push('/cart')
            dispatch(remove())
        }
    }

    const setColorAndImage = (item, index) => {
        setColor(item)
        setPreviewImg(album.images[index])
    }


    return (
        <>
            <div className="product">
                <div className="product__image">
                    <div className="product__image__container">
                        <div className="product__image__container__list">
                            <div className="product__image__list__item" onClick={() => setPreviewImg(product.image01)}>
                                {/* <img src={`.././uploads/${product.image01}`} alt="" /> */}
                                <img src={product.image01} alt="" />
                            </div>
                            <div className="product__image__list__item" onClick={() => setPreviewImg(product.image02)}>
                                {/* <img src={`.././uploads/${product.image02}`} alt="" /> */}
                                <img src={product.image02} alt="" />
                            </div>
                            {
                                album && album.images.map((item, index) => (
                                    <div key={index} className="product__image__list__item" onClick={() => setPreviewImg(item)}>
                                        {/* <img src={`.././uploads/${item}`} alt="" /> */}
                                        <img src={item} alt="" />
                                    </div>
                                ))
                            }
                        </div>
                        <div className="product__image__container__main">
                            <img src={previewImg} alt="" />
                            <img src={previewImg} alt="" />
                        </div>
                    </div>
                    <div className={`product-description ${expand ? 'expand' : ''}`}>
                        <div className="product-description__title">
                            Chi tiết sản phẩm
                        </div>
                        <div className="product-description__content" dangerouslySetInnerHTML={{ __html: product.description }}></div>
                        <div className="product-description__toggle">
                            <Button size="sm" onClick={() => setExpand(!expand)}>
                                {expand ? <i className='bx bx-chevron-up'></i> : <i className='bx bx-chevron-down' ></i>}
                            </Button>
                        </div>
                    </div>

                </div>
                <div className="product__info">
                    <h1 className="product__info__title">{product.title}</h1>
                    <div className="product__info__rating">
                        <i className={`bx ${totalRate > 0 ? (totalRate > 0.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                        <i className={`bx ${totalRate > 1 ? (totalRate > 1.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                        <i className={`bx ${totalRate > 2 ? (totalRate > 2.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                        <i className={`bx ${totalRate > 3 ? (totalRate > 3.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `} ></i>
                        <i className={`bx ${totalRate >= 4 ? (totalRate > 4.5 ? 'bxs-star' : 'bxs-star-half') : 'bx-star'} `}></i>
                    </div>
                    <div className="product__info__item">
                        <span className="product__info__item__price">
                            {numberWithComas(product.price)} đ
                        </span>
                        <span className="product__info__item__oldprice">
                            <del>{numberWithComas(product.oldPrice)} đ</del>
                        </span>
                    </div>
                    <div className="product__info__item">
                        <div className="product__info__item__title">Màu sắc</div>
                        <div className="product__info__item__list">
                            {
                                product.colors.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`product__info__item__list__item ${color === item ? 'active' : ''}`}
                                        // onClick={() => setColor(item)}
                                        onClick={() => setColorAndImage(item, index)}
                                    >
                                        <div
                                            className={`circle bg-${item}`}
                                        ></div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="product__info__item">
                        <div className="product__info__item__title">Kích cỡ</div>
                        <div className="product__info__item__btn" onClick={() => document.querySelector('.custom__size__modal').classList.add('active')}>
                            <button>Xem thông tin Size</button>
                        </div>
                        <div className="product__info__item__list">
                            {
                                product.size.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`product__info__item__list__item ${size === item ? 'active' : ''}`}
                                        onClick={() => setSize(item)}
                                    >
                                        <span
                                            className="product__info__item__list__item__size"
                                        >
                                            {item}
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="product__info__item">
                        <div className="product__info__item__title">Số lượng</div>
                        <div className="product__info__item__quantity">
                            <div
                                className="product__info__item__quantity__btn"
                                onClick={() => updateQuantity('minus')}
                            >
                                <i className="bx bx-minus"></i>
                            </div>
                            <div className="product__info__item__quantity__input">
                                {quantity}
                            </div>
                            <div
                                className="product__info__item__quantity__btn"
                                onClick={() => updateQuantity('plus')}

                            >
                                <i className="bx bx-plus"></i>
                            </div>
                            <p className='product__info__item__quantity__time'>{product.timeShip}</p>
                        </div>
                        {gift && quantity >= gift.quantity && <Gift gift={gift} />}
                    </div>
                    <div className="product__info__item">
                        <Button onClick={addToCart}>Thêm vào giỏ</Button>
                        <Button onClick={gotoCart}>Mua ngay</Button>
                    </div>
                    <hr />
                    <div className="product__info__item__policy">
                        <div className="product__info__item__policy__item">
                            <i className='bx bx-phone-call'></i>
                            <div className="product__info__item__policy__info__name">
                                Đổi trả cực dễ chỉ cần số điện thoại
                            </div>
                        </div>
                        <div className="product__info__item__policy__item">
                            <i className='bx bx-car'></i>
                            <div className="product__info__item__policy__info__name">
                                Miễn phí cho đơn hàng trên 200k
                            </div>
                        </div>
                        <div className="product__info__item__policy__item">
                            <i className='bx bx-refresh'></i>
                            <div className="product__info__item__policy__info__name">
                                60 ngày đổi trả vì bất cứ lý do gì
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`product-description mobile ${expand ? 'expand' : ''}`}>
                    <div className="product-description__title">
                        Chi tiết sản phẩm
                    </div>
                    <div className="product-description__content" dangerouslySetInnerHTML={{ __html: product.description }}></div>
                    <div className="product-description__toggle">
                        <Button size="sm" onClick={() => setExpand(!expand)}>
                            {expand ? <i className='bx bx-chevron-up'></i> : <i className='bx bx-chevron-down' ></i>}
                        </Button>
                    </div>
                </div>
                <div className="product__image__rating__mobile">
                    <hr />
                    <Rating productId={product._id} totalRate={totalRate} commentQuantity={commentList.length} />
                    <hr />
                    {commentList.length > 0 && <h1>Đánh giá của khách hàng</h1>}
                    {
                        commentList && commentList?.map((item, index) => (
                            <Comment key={index} product={product} comment={item} />
                        ))
                    }
                </div>
                <div className="custom__size__modal">
                    <div className="custom__size__modal__container">
                        <div className="custom__size__modal__container__header">
                            <button
                                className='btn bg-main'
                                onClick={() => document.querySelector('.custom__size__modal').classList.remove('active')}
                            >
                                Đóng
                            </button>
                        </div>
                        <div className="custom__size__modal__container__content">
                            <h2>Bảng Size Bigsize Nữ</h2>
                            <hr />
                            <table>
                                <thead>
                                    <tr>
                                        <td>Size</td>
                                        <td>Chiều cao(Cm)</td>
                                        <td>Cân nặng(Kg)</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>M</td>
                                        <td> {"<165"}</td>
                                        <td>{"<55"}</td>
                                    </tr>
                                    <tr>
                                        <td>L</td>
                                        <td>{"<170"}</td>
                                        <td>56-60</td>
                                    </tr>
                                    <tr>
                                        <td>XL</td>
                                        <td>{"<175"}</td>
                                        <td>61-65</td>
                                    </tr>
                                    <tr>
                                        <td>2XL</td>
                                        <td>{"<180"}</td>
                                        <td>66-70</td>
                                    </tr>
                                    <tr>
                                        <td>3XL</td>
                                        <td>{"<180"}</td>
                                        <td>71-80</td>
                                    </tr>
                                    <tr>
                                        <td>4XL</td>
                                        <td>{"<180"}</td>
                                        <td>81-90</td>
                                    </tr>
                                    <tr>
                                        <td>5XL</td>
                                        <td>{"<180"}</td>
                                        <td>91-100</td>
                                    </tr>
                                    <tr>
                                        <td>6XL</td>
                                        <td>{"<180"}</td>
                                        <td>101-110</td>
                                    </tr>
                                    <tr>
                                        <td>7XL</td>
                                        <td>{"<190"}</td>
                                        <td>111-125</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="product__rating">
                <Rating productId={product._id} totalRate={totalRate} commentQuantity={commentList.length} />
                <div className="product__rating__comment">
                    {
                        <Grid
                            col={2}
                            mdCol={2}
                            smCol={2}
                            gap={10}
                        >
                            {commentList && commentList.map((item, index) => (
                                <Comment key={index} product={product} comment={item} />
                            ))}
                        </Grid>

                    }
                </div>
            </div>
        </>
    )
}

ProductView.propTypes = {
    product: PropTypes.object,
}

export default withRouter(ProductView)
