import React from 'react'
import { Route, Switch } from 'react-router-dom';
import Home from '../pages/Home';
import Catalog from '../pages/Catalog';
import Product from '../pages/Product';
import Cart from '../pages/Cart';
import Checkout from '../pages/Checkout';
import Login from '../pages/Login'
import Admin from '../pages/Admin';
// import TextEdittor from '../components/TextEdittor';
import Success from '../pages/Success';
import Error from '../pages/Error';
import Sales from '../pages/Sales';
import About from '../pages/About';
import PolicyCheck from '../pages/PolicyCheck';
import PolicyRefund from '../pages/PolicyRefund';
import Contact from '../pages/Contact';
import CheckPhone from '../pages/CheckPhone';

const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/catalog/:slug" component={Product} />
            <Route path="/catalog" component={Catalog} />
            <Route path="/cart" component={Cart} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/admin/login" exact component={Login} />
            <Route path="/admin" exact component={Admin} />
            {/* <Route path="/test" component={TextEdittor} /> */}
            <Route path="/thankyou" component={Success} />
            <Route path="/error" component={Error} />
            <Route path="/ao-chong-nang-big-size" component={Sales} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/policy-check" component={PolicyCheck} />
            <Route path="/policy-refund" component={PolicyRefund} />
            <Route path="/check-vip" component={CheckPhone} />
        </Switch>
    )
}

export default Routes
