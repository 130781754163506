import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { API_URL } from '../constants/constants';
import { productListActions } from '../redux/product-list/productListSlice';
import { LoadingActions } from '../redux/loading/loadingSlice';

//text edittor 
import { ContentState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import category from '../assets/fake-data/catagory';

const AddProduct = (props) => {
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors } } = useForm();

    //text-edittor
    let _contentState = ContentState.createFromText('Sample content state');

    const raw = convertToRaw(_contentState)

    const [contentState, setContentState] = useState(raw) // ContentState JSON

    const onSubmit = data => {
        const description = draftToHtml(contentState);

        const tag = data.tag.split(",");

        if (tag.findIndex(x => x.trim().toLowerCase() === 'new') < 0) {
            tag.push('new')
        }

        if (data.price > 0 && data.oldPrice > 0) {
            if (tag.findIndex(x => x.trim().toLowerCase() === 'sale') < 0) {
                tag.push('sale')
            }
        }

        if (data.sold >= 200) {
            if (tag.findIndex(x => x.trim().toLowerCase() === 'hot') < 0) {
                tag.push('hot')
            }
        }

        const newProduct = {
            ...data,
            description,
            colors: data.colors.split(","),
            tag,
            size: data.size.split(",")
        }

        const npd = JSON.stringify(newProduct)
        const formData = new FormData();
        formData.set('product', npd)


        dispatch(LoadingActions.setLoading());
        axios.post(`${API_URL}/product`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                dispatch(productListActions.addProduct(res.data.product))
                dispatch(LoadingActions.loadingSuccess());
                alert('Thêm sản phẩm thành công!');
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    return (
        <div className="admin__content__add__product">
            <h1>Thêm sản phẩm mới</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    {...register("title", { required: true })}
                    placeholder="Tên sản phẩm"
                />
                {errors.title && <span className='admin__content__add__product__error'>Tên sản phẩm không được bỏ trống</span>}

                <input
                    {...register("price", { required: true })}
                    placeholder="Giá sản phẩm"
                />
                {errors.price && <span className='admin__content__add__product__error'>Giá sản phẩm không được bỏ trống</span>}

                <input
                    {...register("oldPrice")}
                    placeholder="Giá cũ"
                />

                {/* <input
                    {...register("categorySlug", { required: true })}
                    placeholder="Loại sản phẩm"
                /> */}

                <select {...register("categorySlug", { required: true })}>
                    {category && category.map((item, index) =>
                        <option key={index} value={item.categorySlug}>{item.display}</option>
                    )}
                </select>
                {errors.categorySlug && <span className='admin__content__add__product__error'>Loại sản phẩm không được bỏ trống</span>}

                <input
                    {...register("slug", { required: true })}
                    placeholder="Nhãn của sản phẩm"
                />
                {errors.slug && <span className='admin__content__add__product__error'>Nhãn sản phẩm không được bỏ trống</span>}

                <input
                    {...register("colors", { required: true })}
                    placeholder="Màu"
                />
                {errors.colors && <span className='admin__content__add__product__error'>Màu sản phẩm không được bỏ trống</span>}

                <input
                    {...register("size", { required: true })}
                    placeholder="Size"
                />
                {errors.size && <span className='admin__content__add__product__error'>Kích thước sản phẩm không được bỏ trống</span>}

                <input
                    {...register("tag")}
                    placeholder="Tag sản phẩm (ví dụ : hot,new,....)"
                />

                <input
                    {...register("sold")}
                    placeholder="Số lượng đã bán"
                />

                <input
                    {...register("timeShip")}
                    placeholder="Thời gian giao hàng..."
                />



                {/* <input
                    {...register("image01", { required: true })}
                    type="file"
                />
                {errors.image01 && <span className='admin__content__add__product__error'>Ảnh không được bỏ trống</span>}

                <input
                    {...register("image02", { required: true })}
                    type="file"
                />
                {errors.image02 && <span className='admin__content__add__product__error'>Ảnh không được bỏ trống</span>} */}

                <input
                    {...register("image01", { required: true })}
                    type="text"
                    placeholder='Ảnh đại diện 1'
                />
                {errors.image01 && <span className='admin__content__add__product__error'>Ảnh không được bỏ trống</span>}

                <input
                    {...register("image02", { required: true })}
                    type="text"
                    placeholder='Ảnh đại diện 2'
                />
                {errors.image02 && <span className='admin__content__add__product__error'>Ảnh không được bỏ trống</span>}



                {/* <textarea
                    {...register("description")}
                    placeholder="Mô tả chi tiết về sản phẩm">
                </textarea> */}

                <Editor
                    defaultContentState={contentState}
                    onContentStateChange={setContentState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                />

                <button type="submit" className='btn bg-main btn-block' >Thêm sản phẩm</button>
            </form>
        </div>
    )
}

export default AddProduct
