import axios from 'axios';
import { ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../constants/constants';
import { LoadingActions } from '../redux/loading/loadingSlice';
import { PolicyActions } from '../redux/policy-list/policyListSlice';

const EditPolicy = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const policy = useSelector(state => state.admin.policy);

    const [type, setType] = useState(policy.type)

    const dispatch = useDispatch();

    //text-edittor
    let _contentState = ContentState.createFromText('Bạn muốn tặng quà gì?');

    const raw = convertToRaw(_contentState)

    const [contentState, setContentState] = useState(raw)

    const onSubmit = data => {
        const content = watch('type') === 'tang-qua' ? draftToHtml(contentState) : data.content;
        const updatedPolicy = {
            ...data,
            content
        }

        const updatePolicy = async () => {
            dispatch(LoadingActions.setLoading());
            const res = await axios.post(`${API_URL}/policy/${policy._id}`, updatedPolicy)
            if (res.data.success) {
                dispatch(PolicyActions.editPolicy(res.data.policy))
                dispatch(LoadingActions.loadingSuccess());
                alert('Sửa chính sách thành công!')
            }
        }
        updatePolicy();
    };

    return (
        <div className="add-gift">
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    placeholder="Giá ưu đãi"
                    defaultValue={policy.price}
                    {...register("price", { required: true })}
                />
                {errors.price && <span className='add-gift__error'>Giá ưu đãi không được bỏ trống</span>}

                <select {...register("type")} defaultValue={policy.type} onChange={(e) => setType(e.target.value)}>
                    <option value="tang-qua">Tặng quà</option>
                    <option value="giam-gia">Giảm giá</option>
                </select>

                {
                    type === 'giam-gia' ? <input
                        type="text"
                        placeholder="Số tiền muốn giảm"
                        defaultValue={policy.content}
                        {...register("content", { required: true })}
                    /> :
                        <Editor
                            defaultContentState={contentState}
                            onContentStateChange={setContentState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                        />
                }

                <button type="submit" className='btn bg-main btn-block' >Thêm chính sách</button>
            </form>
        </div>
    )
}

export default EditPolicy
