import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    coupons: []
}

export const couponSlice = createSlice({
    name: 'coupons',
    initialState,
    reducers: {
        getCoupon: (state, action) => {
            state.loading = true
        },
        getCouponSuccess: (state, action) => {
            return {
                loading: false,
                coupons: action.payload
            }
        },
        addCoupon: (state, action) => {
            state.coupons = [...state.coupons, action.payload]
        },
        editCoupon: (state, action) => {
            const updateCoupon = action.payload;
            const couponIndex = state.coupons.findIndex(x => x._id === updateCoupon._id)
            if (couponIndex >= 0) {
                state.coupons[couponIndex] = updateCoupon
            }
        },
        deleteCoupon: (state, action) => {
            const couponId = action.payload;
            state.coupons = state.coupons.filter(x => x._id !== couponId)
        }
    }
})

export const CouponActions = couponSlice.actions

export default couponSlice.reducer
