import axios from 'axios';
import React from 'react'
import { useDispatch } from 'react-redux';
import { API_URL } from '../constants/constants';
import { adminActions } from '../redux/admin/adminSlice';
import { LoadingActions } from '../redux/loading/loadingSlice';
import { PhoneActions } from '../redux/phone-list/phoneListSlice';

const PhoneListItem = (props) => {
    const dispatch = useDispatch()

    const handleEditPhone = () => {
        dispatch(adminActions.setPhone({
            type: 'editPhone',
            phone: props.phone
        }))
    }

    const handleDeletePhone = () => {
        if (window.confirm('Bạn có chắc chắn muốn xóa!')) {
            const deletePhone = async () => {
                dispatch(LoadingActions.setLoading())
                const res = await axios.delete(`${API_URL}/phone/${props.phone.phone}`)
                if (res.data.success) {
                    dispatch(PhoneActions.deletePhone(props.phone.phone))
                    dispatch(LoadingActions.loadingSuccess())
                    alert('Xóa thành công!')
                }
            }
            deletePhone()
        }
    }

    return (
        <tr>
            <td>{props.phone.phone}</td>
            <td>{props.phone.name}</td>
            <td>{props.phone.discount}</td>
            <td>{props.phone.level}</td>
            <td><i onClick={handleEditPhone} className="bx bx-edit"></i></td>
            <td><i onClick={handleDeletePhone} className="bx bx-trash"></i></td>
        </tr>
    )
}

export default PhoneListItem
