import React from 'react'
import Helmet from '../components/Helmet'

const PolicyCheck = () => {
    return (
        <Helmet title='Chính sách kiểm hàng'>
            <div className="policy-page">
                <h1>Chính sách</h1>
                <div className="policy-page__check">
                    <h2>1. CHÍNH SÁCH KIỂM HÀNG</h2>
                    <div className="policy-page__check__content">
                        <h3>Giao nhận hàng hóa - Kiểm hàng trước khi thanh toán</h3>
                        <p>- Trước khi nhận hàng và thanh toán, Quý Khách được quyền kiểm tra sản phẩm. Không hỗ trợ thử hàng.</p>
                        <p>- Quý Khách vui lòng mở gói hàng kiểm tra để đảm bảo đơn hàng được giao đúng mẫu mã, số lượng như đơn hàng đã đặt. Không thử hay dùng thử sản phẩm.</p>
                        <p>- Sau khi đồng ý với món hàng được giao đến, Quý Khách thanh toán với nhân viên giao hàng (trường hợp đơn hàng được ship COD) và nhận hàng.</p>
                        <p>- Trường hợp Quý Khách không ưng ý với sản phẩm, Quý Khách có thể từ chối nhận hàng. Tại đây, Mũm sẽ thu thêm chi phí hoàn hàng, tương đương với phí ship của đơn hàng Quý khách đã đặt.</p>
                    </div>
                    <div className="policy-page__check__ps">
                        <h3>Lưu ý</h3>
                        <p>- Khi Quý Khách kiểm tra đơn hàng, nhân viên giao nhận buộc phải đợi Quý Khách kiểm tra hàng hóa bên trong gói hàng. Trường hợp nhân viên từ chối cho Quý Khách kiểm tra hàng hóa, Quý Khách vui lòng liên hệ với Mũm qua hotline: 084.995.5678 để được hỗ trợ.</p>
                        <p>- Quý Khách tránh dùng vật sắc nhọn để mở gói hàng để tránh gây hư hỏng cho sản phẩm bên trong. Đối với những trường hợp sản phẩm bị hư hỏng do lỗi từ phía Khách hàng, Mũm rất tiếc không thể hỗ trợ Quý Khách đổi/trả/bảo hành sản phẩm.</p>
                        <p>- Trường hợp khách hàng nhờ người thân nhận hộ, khi về nhà mới kiểm tra sản phẩm. Thì nên quay lại video quá trình bóc và kiểm hàng để được hưởng các chính sách của Mũm</p>
                    </div>
                </div>
                <div className="policy-page__check">
                    <h2>2. CHÍNH SÁCH ĐỔI HÀNG</h2>
                    <div className="policy-page__check__content">
                        <h3>ĐIỀU KIỆN ĐỔI HÀNG</h3>
                        <p>Mũm nhận đổi/trả sản phẩm với các quy định sau:</p>
                        <p>- Thời gian đổi hàng trong vòng 15 ngày kể từ ngày nhận hàng</p>
                        <p>- Thời gian được tính từ thời điểm xuất hóa đơn</p>
                        <p>- Sản phẩm chưa qua sử dụng, không bị dơ bẩn, còn nguyên tem mác, bao bì sản phẩm (nếu có)</p>
                        <p>- Sản phẩm được chọn để đổi phải có giá trị cao hơn hoặc ngang bằng sản phẩm đổi</p>
                        <p>- Không hoàn lại tiền thừa trong trường hợp sản phẩm được chọn để đổi có giá trị thấp hơn sản phẩm đổi</p>
                    </div>
                    <div className="policy-page__check__ps">
                        <h3>CÁC TRƯỜNG HỢP ĐỔI HÀNG</h3>
                        <span>Về phía Mũm - Miễn phí đổi hàng cho khách mua sắm tại Mũm trong các trường hợp:</span>
                        <p>- Sản phẩm bị lỗi từ nhà sản xuất </p>
                        <p>- Giao nhầm hàng, nhầm sản phẩm </p>
                        <p>- Hư hỏng trong quá trình vận chuyển </p>
                        <span>Về phía khách hàng:</span>
                        <p>- Trong trường hợp không thích màu, muốn đổi mẫu khác/sản phẩm khác.</p>
                        <p>- Khách hàng vui lòng hỗ trợ 2 chiều phí vận chuyển đổi hàng tại kho Mũm (phí vận chuyển sẽ là 30.000VNĐ)</p>
                    </div>
                </div>
            </div>
        </Helmet>
    )
}

export default PolicyCheck