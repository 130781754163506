import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Editor } from "react-draft-wysiwyg";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingActions } from '../redux/loading/loadingSlice';
import { API_URL } from '../constants/constants';
import htmlToDraft from 'html-to-draftjs';
import { GiftActions } from '../redux/gift-list/giftListSlice';

const EditGift = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const gift = useSelector(state => state.admin.gift)

    const dispatch = useDispatch();

    //text-edittor
    const blocksFromHtml = htmlToDraft(gift.content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState1 = EditorState.createWithContent(contentState);

    const [editorState, SetEditorState] = useState(editorState1)

    const onEditorStateChange = (editorState) => {
        SetEditorState(editorState)
    };


    const onSubmit = data => {
        const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const updatedGift = { ...data, content }

        const updateGift = async () => {
            const productSlug = gift.productSlug;
            dispatch(LoadingActions.setLoading());
            const res = await axios.post(`${API_URL}/gift/${productSlug}`, updatedGift)
            if (res.data.success) {
                dispatch(GiftActions.editGift({ updatedGift: res.data.gift, productSlug }))
                dispatch(LoadingActions.loadingSuccess());
                alert('Sửa khuyến mãi thành công!')
            }
        }
        updateGift();
    };


    return (
        <div className="add-gift">
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    defaultValue={gift.productSlug}
                    {...register("productSlug", { required: true })}
                />
                {errors.productSlug && <span className='admin__content__add__product__error'>Nhãn sản phẩm không được bỏ trống</span>}

                <input
                    type="number"
                    defaultValue={Number(gift.quantity)}
                    {...register("quantity", { required: true })}
                />
                {errors.quantity && <span className='admin__content__add__product__error'>Số lượng không được bỏ trống</span>}

                <Editor
                    defaultContentState={contentState}
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={onEditorStateChange}
                />

                <button type="submit" className='btn bg-main btn-block' >Sửa khuyến mãi</button>
            </form>
        </div>
    )
}

export default EditGift
