import React from 'react'
import { Link } from 'react-router-dom'
import Grid from './Grid'
import logo from '../assets/images/logo714.png';

const footerAboutLink = [
    {
        display: 'Giới thiệu',
        path: '/about'
    },
    {
        display: 'Liên hệ',
        path: '/contact'
    },
    {
        display: 'Tin tức',
        path: '/about'
    },
    // {
    //     display: 'Hệ thống cửa hàng',
    //     path: '/about'
    // }
]

const footerCustomerLink = [
    {
        display: 'Chính sách kiểm hàng',
        path: '/policy-check'
    },
    {
        display: 'Chính sách đổi hàng',
        path: '/policy-refund'
    },
]
const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <Grid
                    col={4}
                    mdCol={2}
                    smCol={1}
                    gap={10}
                >
                    <div>
                        <div className="footer__title">Tổng đài hỗ trợ</div>
                        <div className="footer__content">
                            <p>Liên hệ đặt hàng <a href="tel:0868488884"><strong>0868 488 884</strong></a></p>
                            <p>Thắc mắc đơn hàng <a href="tel:0868488884"><strong>0868 488 884</strong></a></p>
                            <p>Góp ý, khiếu nại <a href="tel:0868488884"><strong>0868 488 884</strong></a></p>
                        </div>
                    </div>
                    <div className="about">
                        <div className="footer__title">Về Mũm</div>
                        <div className="footer__content">
                            {footerAboutLink.map((item, index) => (
                                <p
                                    key={index}
                                >
                                    <Link to={item.path}>
                                        {item.display}
                                    </Link>
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className="care">
                        <div className="footer__title">Chăm sóc khách hàng</div>
                        <div className="footer__content">
                            {footerCustomerLink.map((item, index) => (
                                <p
                                    key={index}
                                >
                                    <Link to={item.path}>
                                        {item.display}
                                    </Link>
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className="footer__about">
                        <p>
                            <Link to="/">
                                <img src={logo} className="footer__logo" alt="" />
                            </Link>
                        </p>
                        {/* <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea ut, omnis nesciunt dicta beatae fuga dolor tempore? Architecto eligendi molestiae accusamus omnis? Saepe iusto, fuga laborum quas esse consequuntur officia.
                        </p> */}
                    </div>
                </Grid>
            </div>
        </footer>
    )
}

export default Footer
