import React from 'react';
// import { Link } from 'react-router-dom';

import Helmet from '../components/Helmet';
import HeroSlider from '../components/HeroSlider';
import PolicyCard from '../components/PolicyCard';
import Section, { SectionTitle, SectionBody } from '../components/Section';
import Grid from '../components/Grid';

import heroSliderData from '../assets/fake-data/hero-slide';
import policy from '../assets/fake-data/policy';
// import productData from '../assets/fake-data/product';
import ProductCard from '../components/ProductCard';
// import banner from '../assets/images/cover.jpg';
import { useSelector } from 'react-redux';

const Home = () => {

    const products = useSelector(state => state.productList.product)

    // const getProducts = (count) => {
    //     const max = products.length - count
    //     const min = 0
    //     const start = Math.floor(Math.random() * (max - min) + min)
    //     return products.slice(start, start + count)
    // }

    const getProductByTag = (tag) => {
        const productByTag = products.filter(pd => pd.tag.includes(tag));
        return productByTag
    }

    return (
        <Helmet title="Trang chủ">
            {/* Hero slider */}
            <HeroSlider data={heroSliderData} control={true} auto={true} />
            {/* End Hero slider */}
            {/* policy section */}
            <Section>
                <SectionBody>
                    <Grid
                        col={4}
                        mdCol={2}
                        smCol={1}
                        gap={10}
                    >
                        {
                            policy.map((item, index) =>
                                // <Link key={index} to="policy">
                                <PolicyCard
                                    key={index}
                                    name={item.name}
                                    description={item.description}
                                    icon={item.icon}
                                />
                                // </Link>
                            )
                        }
                    </Grid>
                </SectionBody>
            </Section>
            {/* end policy section */}
            {/* best sell section */}
            <Section>
                <SectionTitle>
                    <p>Top bán chạy trong tuần</p>
                </SectionTitle>
                <SectionBody>
                    <Grid
                        col={4}
                        mdCol={2}
                        smCol={2}
                        gap={10}
                    >
                        {
                            getProductByTag('hot').map((item, index) => (
                                <ProductCard
                                    key={index}
                                    img01={item.image01}
                                    img02={item.image02}
                                    name={item.title}
                                    price={Number(item.price)}
                                    oldPrice={Number(item.oldPrice)}
                                    slug={item.slug}
                                    id={item._id}
                                    tag={item.tag}
                                    rating={item.rating ? item.rating : 5}
                                    sold={item.sold ? item.sold : 0}
                                >
                                </ProductCard>
                            ))
                        }
                    </Grid>
                </SectionBody>
            </Section>
            {/* end best sell section */}
            {/* new arrival section */}
            <Section>
                <SectionTitle>
                    <p>Sản phẩm mới</p>
                </SectionTitle>
                <SectionBody>
                    <Grid
                        col={4}
                        mdCol={2}
                        smCol={2}
                        gap={10}
                    >
                        {
                            getProductByTag('new').map((item, index) => (
                                <ProductCard
                                    key={index}
                                    img01={item.image01}
                                    img02={item.image02}
                                    name={item.title}
                                    price={Number(item.price)}
                                    oldPrice={Number(item.oldPrice)}
                                    slug={item.slug}
                                    id={item._id}
                                    tag={item.tag}
                                    rating={item.rating ? item.rating : 5}
                                    sold={item.sold ? item.sold : 0}
                                >
                                </ProductCard>
                            ))
                        }
                    </Grid>
                </SectionBody>
            </Section>
            {/* end new arrival section */}
            {/* banner */}
            {/* <Section>
                <div>
                    <Link to="/catalog">
                        <img style={{ maxWidth: '100%' }} src={banner} alt="" />
                    </Link>
                </div>
            </Section> */}
            {/* end banner */}
            {/* popular product section */}
            <Section className="sales">
                <SectionTitle>
                    <p>Siêu khuyến mãi</p>
                </SectionTitle>
                <SectionBody>
                    <Grid
                        col={4}
                        mdCol={2}
                        smCol={2}
                        gap={10}
                    >
                        {
                            getProductByTag('sale').map((item, index) => (
                                <ProductCard
                                    key={index}
                                    img01={item.image01}
                                    img02={item.image02}
                                    name={item.title}
                                    price={Number(item.price)}
                                    oldPrice={Number(item.oldPrice)}
                                    slug={item.slug}
                                    id={item._id}
                                    tag={item.tag}
                                    rating={item.rating ? item.rating : 5}
                                    sold={item.sold ? item.sold : 0}
                                >
                                </ProductCard>
                            ))
                        }
                    </Grid>
                </SectionBody>
            </Section>
            {/* end popular product section */}
        </Helmet>
    );
};

export default Home;
