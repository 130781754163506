const colors = [
    {
        display: "Trắng",
        color: "trang",
        hex: "#fff"
    },
    {
        display: "Đen",
        color: "den",
        hex: "#000"
    },
    {
        display: "Cam",
        color: "cam",
        hex: "#fbb96b"
    },
    {
        display: "Xanh dương",
        color: "xanh-duong",
        hex: "#4267b2"
    },
    {
        display: "Xanh lá",
        color: "xanh-la",
        hex: "rgb(141, 194, 141)"
    },
    {
        display: "Nâu nhạt",
        color: "nau",
        hex: "rgb(126, 91, 91)"
    },
    {
        display: "Xanh Navy",
        color: "navy",
        hex: "navy"
    },
    {
        display: "Xám",
        color: "xam",
        hex: "gray"
    },
    {
        display: "Be",
        color: "be",
        hex: "#ac9c85"
    },
    {
        display: "Xanh Rêu",
        color: "reu",
        hex: "#3e604f"
    },
    {
        display: "Đỏ",
        color: "do",
        hex: "#ff0000"
    },
    {
        display: "Vàng",
        color: "vang",
        hex: "#f2cf1d"
    },
    {
        display: "Hồng",
        color: "hong",
        hex: "#b9a7a5"
    },
    {
        display: "Xanh đậm",
        color: "xanh-dam",
        hex: "#000a4a"
    },
    {
        display: "Xanh nhạt",
        color: "xanh-nhat",
        hex: "#8d99e1"
    }

]

export default colors