import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    order: []
}

export const oderListSlice = createSlice({
    name: 'orderList',
    initialState,
    reducers: {
        getOrder: (state, action) => {
            state.loading = true
        },
        getOrderSuccess: (state, action) => {
            return {
                loading: false,
                order: action.payload
            }
        },
        editOrder: (state, action) => {
            const updateOrder = action.payload;
            const updateOrderIndex = state.order.findIndex(x => x._id === updateOrder._id)
            if (updateOrderIndex >= 0) {
                state.order[updateOrderIndex] = updateOrder
            }
        },
        deleteOrder: (state, action) => {
            const deleteOrderID = action.payload

            state.order = state.order.filter(order => order._id !== deleteOrderID)
        }
    }
})

export const OrderListActions = oderListSlice.actions

export default oderListSlice.reducer
