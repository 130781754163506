import axios from 'axios';
import React from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { API_URL } from '../constants/constants';
import { LoadingActions } from '../redux/loading/loadingSlice';
import { PhoneActions } from '../redux/phone-list/phoneListSlice';

const AddPhone = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const dispatch = useDispatch();

    const onSubmit = data => {
        const createPhone = async () => {
            dispatch(LoadingActions.setLoading());
            const res = await axios.post(`${API_URL}/phone`, data)
            if (res.data.success) {
                dispatch(LoadingActions.loadingSuccess());
                dispatch(PhoneActions.addPhone(res.data.phone))
                alert('Thêm sđt giảm giá thành công!')
            }
        }
        createPhone();
    };

    return (
        <div className="add-gift">
            <h2>Thêm SĐT giảm giá</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    placeholder="SĐT"
                    {...register("phone", { required: true })}
                />
                {errors.phone && <span className='add-gift__error'>Sđt ưu đãi không được bỏ trống</span>}

                <input
                    placeholder="Tên khách hàng"
                    {...register("name", { required: true })}
                />
                {errors.name && <span className='add-gift__error'>Tên khách hàng không được bỏ trống</span>}

                <input
                    placeholder="Phần trăm giảm giá"
                    type="number"
                    {...register("discount", { required: true })}
                    defaultValue="10"
                />
                {errors.discount && <span className='add-gift__error'>Số tiền không được bỏ trống</span>}

                <input
                    placeholder="Cấp độ vip"
                    type="number"
                    {...register("level", { required: true })}
                    defaultValue="1"
                />
                <button type="submit" className='btn bg-main btn-block' >Thêm SĐT giảm giá</button>
            </form>
        </div>
    )
}

export default AddPhone
